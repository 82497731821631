import React from 'react';
import DropdownSettings from "components/Project/Overview/DropdownSettings"
import DropdownSelectBank from 'components/BancoPrecios/dropdowns/DropdownSelectBank';
import i18n from 'i18n';

export const SummaryNav = {
    'paths': [
    /*{
        
        'label': i18n.t('Panel'),
        'to': '/project',
        'isActive': true,
        'disabled': false,
        
    }*/,{
        'label': i18n.t('Presupuesto'),
        'to': '/budgeting',
        'isActive': false,
        'disabled': false,
    },
    
 
    // {
    //     'label': i18n.t('Proveedores'),
    //     'to': '/budgeting/providers',
    //     'isActive': false,
    //     'disabled': true,
    //     'muypronto': true
    // },
    
    /*
    {
        'label': i18n.t('Planning'),
        'to': '/planning/kanban',
        'isActive': false,
        'disabled': true,
        'muypronto': true
    },
    */
    {
        'label': i18n.t('Facturas'),
        'to': '/invoices',
        'isActive': false,
        'disabled': false,
        'muypronto': false
    },
    {
        'label': i18n.t('Actas'),
        'to': '/actas',
        'isActive': false,
        'disabled': false,
      
    },
    {
        'label': i18n.t('Certificaciones'),
        'to': '/certificaciones',
        'isActive': false,
        'disabled': false,
      
    },

    ],
    'options': <DropdownSettings/>
}
export const BudgetingNav = {
    'paths': [
   /*{
        
        'label': i18n.t('Panel'),
        'to': '/project',
        'isActive': false,
        'disabled': false,
              
    }*/,{
        'label': i18n.t('Presupuesto'),
        'to': '/budgeting',
        'isActive': true,
        'disabled': false,
    },
  
    // {
    //     'label': i18n.t('Proveedores'),
    //     'to': '/budgeting/providers',
    //     'isActive': false,
    //     'disabled': true,
    //     'muypronto': true
    // },
    /*{
        'label': i18n.t('Planning'),
        'to': '/planning/kanban',
        'isActive': false,
        'disabled': true,
        'muypronto': true
    },
      */
    {
        'label': i18n.t('Facturas'),
        'to': '/invoices',
        'isActive': false,
        'disabled': false,
        'muypronto': false
    },
    {
        'label': i18n.t('Actas'),
        'to': '/actas',
        'isActive': false,
        'disabled': false,
      
    },
    {
        'label': i18n.t('Certificaciones'),
        'to': '/certificaciones',
        'isActive': false,
        'disabled': false,
      
    },
    {
        'label': i18n.t('Historico Certificaciones'),
        'to': '/certhistory',
        'isActive': false,
        'disabled': false,
      
    },
    {
        'label': i18n.t('Extras y Contradictorios'),
        'to': '/extras',
        'isActive': false,
        'disabled': false,
      
    },
 
],     'options': <DropdownSettings/>}

export const BudgetingNavFinal = {
    'paths': [
    /*{
        
        'label': i18n.t('Panel'),
        'to': '/project',
        'isActive': false,
        'disabled': false,
              
    }*/,{
        'label': i18n.t('Presupuesto de Coste'),
        'to': '/budgeting',
        'isActive': false,
        'disabled': false,
    },
    {
        'label': i18n.t('Presupuesto Cliente'),
        'to': '/budgeting/final',
        'isActive': true,
        'disabled': false,
    },
    // {
    //     'label': i18n.t('Proveedores'),
    //     'to': '/budgeting/providers',
    //     'isActive': false,
    //     'disabled': true,
    //     'muypronto': true
    // },
    /*{
        'label': i18n.t('Planning'),
        'to': '/planning/kanban',
        'isActive': false,
        'disabled': true,
        'muypronto': true
    },  */
    {
        'label': i18n.t('Facturas'),
        'to': '/invoices',
        'isActive': false,
        'disabled': false,
        'muypronto': false
    },
    {
        'label': i18n.t('Actas'),
        'to': '/actas',
        'isActive': false,
        'disabled': false,
      
    },
    {
        'label': i18n.t('Certificaciones'),
        'to': '/certificaciones',
        'isActive': false,
        'disabled': false,
      
    },
    {
        'label': i18n.t('Historico Certificaciones'),
        'to': '/certhistory',
        'isActive': false,
        'disabled': false,
      
    },
    
 
],     'options': <DropdownSettings/>}

export const PlanningNav = {
    'paths': [
    /*{
        'label': i18n.t('Panel'),
        'to': '/project',
        'isActive': false,
        'disabled': false,
              
    }*/,{
        'label': i18n.t('Presupuesto'),
        'to': '/budgeting',
        'isActive': false,
        'disabled': false,
    },
   
    // {
    //     'label': i18n.t('Proveedores'),
    //     'to': '/budgeting/providers',
    //     'isActive': false,
    //     'disabled': true,
    //     'muypronto': true
    // },
    {
        'label': i18n.t('Planning'),
        'to': '/planning/kanban',
        'isActive': true,
        'disabled': true,
        'muypronto': true
    },
    {
        'label': i18n.t('Facturas'),
        'to': '/invoices',
        'isActive': false,
        'disabled': false,
        'muypronto': false
    }, {
        'label': i18n.t('Actas'),
        'to': '/actas',
        'isActive': false,
        'disabled': false,
      
    },
    {
        'label': i18n.t('Certificaciones'),
        'to': '/certificaciones',
        'isActive': false,
        'disabled': false,
      
    },

],     'options': <DropdownSettings/>}

export const ProvidersNav = {
    'paths': [
    /*{
        
        'label': i18n.t('Panel'),
        'to': '/project',
        'isActive': false,
        'disabled': false,
              
    }*/,{
        'label': i18n.t('Presupuesto'),
        'to': '/budgeting',
        'isActive': false,
        'disabled': false,
    },
// {
//         'label': i18n.t('Proveedores'),
//         'to': '/budgeting/providers',
//         'isActive': true,
//         'disabled': false,
//     },
    /*{
        'label': i18n.t('Planning'),
        'to': '/planning/kanban',
        'isActive': false,
        'disabled': true,
        'muypronto': true
    },
      */
    {
        'label': i18n.t('Facturas'),
        'to': '/invoices',
        'isActive': false,
        'disabled': false,
        'muypronto': false
    },
    {
        'label': i18n.t('Actas'),
        'to': '/actas',
        'isActive': false,
        'disabled': false,
      
    },
    {
        'label': i18n.t('Certificaciones'),
        'to': '/certificaciones',
        'isActive': false,
        'disabled': false,
      
    },
    
],     'options': <DropdownSettings/>}

export const FacturasNav = {
    'paths': [
    /*{
        'label': i18n.t('Panel'),
        'to': '/project',
        'isActive': false,
        'disabled': false,
              
    }*/,{
        'label': i18n.t('Presupuesto'),
        'to': '/budgeting',
        'isActive': false,
        'disabled': false,
    },
//    {
//         'label': i18n.t('Proveedores'),
//         'to': '/budgeting/providers',
//         'isActive': false,
//         'disabled': true,
//         'muypronto': true
//     },
    {
        'label': i18n.t('Facturas'),
        'to': '/invoices',
        'isActive': true,
        'disabled': false,
        'muypronto': false
    },
    {
        'label': i18n.t('Actas'),
        'to': '/actas',
        'isActive': false,
        'disabled': false,
      
    },
    {
        'label': i18n.t('Certificaciones'),
        'to': '/certificaciones',
        'isActive': false,
        'disabled': false,
      
    },
    
],     'options': <DropdownSettings/>}


export const ActasNav = {
    'paths': [
    /*{
        
        'label': i18n.t('Panel'),
        'to': '/project',
        'isActive': true,
        'disabled': false,
        
    }*/,{
        'label': i18n.t('Presupuesto'),
        'to': '/budgeting',
        'isActive': false,
        'disabled': false,
    },
    
   
    // {
    //     'label': i18n.t('Proveedores'),
    //     'to': '/budgeting/providers',
    //     'isActive': false,
    //     'disabled': true,
    //     'muypronto': true
    // },
    /*
    {
        'label': i18n.t('Planning'),
        'to': '/planning/kanban',
        'isActive': false,
        'disabled': true,
        'muypronto': true
    },
    */
   
    {
        'label': i18n.t('Facturas'),
        'to': '/invoices',
        'isActive': false,
        'disabled': false,
        'muypronto': false
    }, {
        'label': i18n.t('Actas'),
        'to': '/actas',
        'isActive': true,
        'disabled': false,
      
    },
    {
        'label': i18n.t('Certificaciones'),
        'to': '/certificaciones',
        'isActive': false,
        'disabled': false,
      
    },
    ],
    'options': <DropdownSettings/>

}



export const CertificacionesNav = {
    'paths': [
    /*{
        
        'label': i18n.t('Panel'),
        'to': '/project',
        'isActive': true,
        'disabled': false,
        
    }*/,{
        'label': i18n.t('Presupuesto'),
        'to': '/budgeting',
        'isActive': false,
        'disabled': false,
    },
    
   
    // {
    //     'label': i18n.t('Proveedores'),
    //     'to': '/budgeting/providers',
    //     'isActive': false,
    //     'disabled': true,
    //     'muypronto': true
    // },
    /*
    {
        'label': i18n.t('Planning'),
        'to': '/planning/kanban',
        'isActive': false,
        'disabled': true,
        'muypronto': true
    },
    */
   
    {
        'label': i18n.t('Facturas'),
        'to': '/invoices',
        'isActive': false,
        'disabled': false,
        'muypronto': false
    }, {
        'label': i18n.t('Actas'),
        'to': '/actas',
        'isActive': false,
        'disabled': false,
      
    },
    {
        'label': i18n.t('Certificaciones'),
        'to': '/certificaciones',
        'isActive': true,
        'disabled': false,
      
    },{
        'label': i18n.t('Historico Certificaciones'),
        'to': '/certhistory',
        'isActive': false,
        'disabled': false,
      
    },
    {
        'label': i18n.t('Extras y Contradictorios'),
        'to': '/extras',
        'isActive': false,
        'disabled': false,
      
    },
    ],
    'options': <DropdownSettings/>

}

export const CertHistoryNav = {
    'paths': [
    /*{
        
        'label': i18n.t('Panel'),
        'to': '/project',
        'isActive': true,
        'disabled': false,
        
    }*/,{
        'label': i18n.t('Presupuesto'),
        'to': '/budgeting',
        'isActive': false,
        'disabled': false,
    },
    
   
    // {
    //     'label': i18n.t('Proveedores'),
    //     'to': '/budgeting/providers',
    //     'isActive': false,
    //     'disabled': true,
    //     'muypronto': true
    // },
    /*
    {
        'label': i18n.t('Planning'),
        'to': '/planning/kanban',
        'isActive': false,
        'disabled': true,
        'muypronto': true
    },
    */
   
    {
        'label': i18n.t('Facturas'),
        'to': '/invoices',
        'isActive': false,
        'disabled': false,
        'muypronto': false
    }, {
        'label': i18n.t('Actas'),
        'to': '/actas',
        'isActive': false,
        'disabled': false,
      
    },
    {
        'label': i18n.t('Certificaciones'),
        'to': '/certificaciones',
        'isActive': false,
        'disabled': false,
      
    },
    {
        'label': i18n.t('Historico Certificaciones'),
        'to': '/certhistory',
        'isActive': true,
        'disabled': false,
      
    },
    {
        'label': i18n.t('Extras y Contradictorios'),
        'to': '/extras',
        'isActive': false,
        'disabled': false,
      
    },
    ],
    'options': <DropdownSettings/>

}

export const ExtrasAndContradictionsNav = {
    'paths': [
    /*{
        
        'label': i18n.t('Panel'),
        'to': '/project',
        'isActive': true,
        'disabled': false,
        
    }*/,{
        'label': i18n.t('Presupuesto'),
        'to': '/budgeting',
        'isActive': false,
        'disabled': false,
    },
    
   
    // {
    //     'label': i18n.t('Proveedores'),
    //     'to': '/budgeting/providers',
    //     'isActive': false,
    //     'disabled': true,
    //     'muypronto': true
    // },
    /*
    {
        'label': i18n.t('Planning'),
        'to': '/planning/kanban',
        'isActive': false,
        'disabled': true,
        'muypronto': true
    },
    */
   
    {
        'label': i18n.t('Facturas'),
        'to': '/invoices',
        'isActive': false,
        'disabled': false,
        'muypronto': false
    }, {
        'label': i18n.t('Actas'),
        'to': '/actas',
        'isActive': false,
        'disabled': false,
      
    },
    {
        'label': i18n.t('Certificaciones'),
        'to': '/certificaciones',
        'isActive': false,
        'disabled': false,
      
    },
    {
        'label': i18n.t('Historico Certificaciones'),
        'to': '/certhistory',
        'isActive': false,
        'disabled': false,
      
    },
    {
        'label': i18n.t('Extras y Contradictorios'),
        'to': '/extras',
        'isActive': true,
        'disabled': false,
      
    },
    ],
    'options': <DropdownSettings/>

}



export const DocumentsNav = {
    'paths': [
        {
            'label': i18n.t('Documentos'),
            'to': '/documents',
            'isActive': true,
            'disabled': false,
        }
]}

export const ProfileNav = {
    'paths': [
    {
        'label': i18n.t('General'),
        'to': '/generalProfile',
        'isActive': true,
        'disabled': false,
    },{
        'label': i18n.t('Perfil'),
        'to': '/profile',
        'isActive': false,
        'disabled': false,
    },
    // {
    //     'label': i18n.t('Plantillas'),
    //     'to': '/templateManagement',
    //     'isActive': false,
    //     'disabled': false,
    // },

    {
        'label': i18n.t('Gestión de equipo'),
        'to': '/gestionEquipo',
        'isActive': false,
        'disabled': false,
    },
    {
        'label': i18n.t('Suscripción'),
        'to': '/subscription',
        'isActive': false,
        'disabled': false,
    },
    {
            'label': i18n.t('Personalizar PDF'),
            'to': '/general-pdf',
            'isActive': false,
            'disabled': false,
        }
   
]

}
export const ProfileNav2 = {
    'paths': [
    {
        'label': i18n.t('General'),
        'to': '/generalProfile',
        'isActive': false,
        'disabled': false,
    },{
        'label': i18n.t('Perfil'),
        'to': '/profile',
        'isActive': true,
        'disabled': false,
    },
    // {
    //     'label': i18n.t('Plantillas'),
    //     'to': '/templateManagement',
    //     'isActive': false,
    //     'disabled': false,
    // },

    {
        'label': i18n.t('Gestión de equipo'),
        'to': '/gestionEquipo',
        'isActive': false,
        'disabled': false,
    },
    {
        'label': i18n.t('Suscripción'),
        'to': '/subscription',
        'isActive': false,
        'disabled': false,
    },
     {
                'label': i18n.t('Personalizar PDF'),
                'to': '/general-pdf',
                'isActive': false,
                'disabled': false,
            }
   
    
]

}

export const ProfileNav3 = {
    'paths': [
    {
        'label': i18n.t('General'),
        'to': '/generalProfile',
        'isActive': false,
        'disabled': false,
    },{
        'label': i18n.t('Perfil'),
        'to': '/profile',
        'isActive': false,
        'disabled': false,
    },
    // {
    //     'label': i18n.t('Plantillas'),
    //     'to': '/templateManagement',
    //     'isActive': false,
    //     'disabled': false,
    // },
    {
        'label': i18n.t('Gestión de equipo'),
        'to': '/gestionEquipo',
        'isActive': false,
        'disabled': false,
    },
    {
        'label': i18n.t('Suscripción'),
        'to': '/subscription',
        'isActive': true,
        'disabled': false,
    },
     {
                'label': i18n.t('Personalizar PDF'),
                'to': '/general-pdf',
                'isActive': false,
                'disabled': false,
            }
   
    
]

}

export const ProfileNav4 = {
    'paths': [
    {
        'label': i18n.t('General'),
        'to': '/generalProfile',
        'isActive': false,
        'disabled': false,
    },{
        'label': i18n.t('Perfil'),
        'to': '/profile',
        'isActive': false,
        'disabled': false,
    },
    // {
    //     'label': i18n.t('Plantillas'),
    //     'to': '/templateManagement',
    //     'isActive': true,
    //     'disabled': false,
    // },
    {
        'label': i18n.t('Gestión de equipo'),
        'to': '/gestionEquipo',
        'isActive': false,
        'disabled': false,
    },
    {
        'label': i18n.t('Suscripción'),
        'to': '/subscription',
        'isActive': false,
        'disabled': false,
    },
    
   
] 
}


export const ProfileNav6 = {
    'paths': [
    {
        'label': i18n.t('General'),
        'to': '/generalProfile',
        'isActive': false,
        'disabled': false,
    },{
        'label': i18n.t('Perfil'),
        'to': '/profile',
        'isActive': false,
        'disabled': false,
    },
    // {
    //     'label': i18n.t('Plantillas'),
    //     'to': '/templateManagement',
    //     'isActive': false,
    //     'disabled': false,
    // },
    {
        'label': i18n.t('Gestión de equipo'),
        'to': '/gestionEquipo',
        'isActive': false,
        'disabled': false,
    },
    {
        'label': i18n.t('Suscripción'),
        'to': '/subscription',
        'isActive': false,
        'disabled': false,
    },
     {
                'label': i18n.t('Personalizar PDF'),
                'to': '/general-pdf',
                'isActive': true,
                'disabled': false,
            }


]

}

export const ProfileNavTeamManagementSection = {
    'paths': [
    {
        'label': i18n.t('General'),
        'to': '/generalProfile',
        'isActive': false,
        'disabled': false,
    },{
        'label': i18n.t('Perfil'),
        'to': '/profile',
        'isActive': false,
        'disabled': false,
    },
    // {
    //     'label': i18n.t('Plantillas'),
    //     'to': '/templateManagement',
    //     'isActive': false,
    //     'disabled': false,
    // },
    {
        'label': i18n.t('Gestión de equipo'),
        'to': '/gestionEquipo',
        'isActive': true,
        'disabled': false,
    },
    {
        'label': i18n.t('Suscripción'),
        'to': '/subscription',
        'isActive': false,
        'disabled': false,
    },
     {
                    'label': i18n.t('Personalizar PDF'),
                    'to': '/general-pdf',
                    'isActive': false,
                    'disabled': false,
                }

    
   
] 
}
export const ProfileNav5 = {
    'paths': [
    {
        'label': i18n.t('General'),
        'to': '/generalProfile',
        'isActive': false,
        'disabled': false,
    },{
        'label': i18n.t('Perfil'),
        'to': '/profile',
        'isActive': false,
        'disabled': false,
    },
    // {
    //     'label': i18n.t('Plantillas'),
    //     'to': '/templateManagement',
    //     'isActive': false,
    //     'disabled': false,
    // },
    {
        'label': i18n.t('Gestión de equipo'),
        'to': '/gestionEquipo',
        'isActive': false,
        'disabled': false,
    },
    {
        'label': i18n.t('Suscripción'),
        'to': '/subscription',
        'isActive': false,
        'disabled': false,
    },
   
]

}

export const BancoDePreciosNavTemplateUnique = {
    'paths': [
    {
        'label': i18n.t('Mi banco de precios'),
        'to': '/bancodeprecios',
        'isActive': false,
        'disabled': false,
    },{
        'label': i18n.t('Banco de precios'),
        'to': '/bancodeprecios',
        'isActive': false,
        'disabled': false,
        'imComponent':true,
    },
    {
        'label': i18n.t('Plantillas'),
        'to': '/bancodeprecios/templates',
        'isActive': false,
        'disabled': false,
    },
    {
        'label': i18n.t('Plantilla'),
        'to': '/bancodeprecios/templateV2',
        'isActive': true,
        'disabled': false,
    },
   
    ],
    'options2': <DropdownSelectBank isActive={false} key={3}/>
}

const BPNavPaths = {
    MY_BANK: "myBank",
    BANK: "bank",
    TEMPLATES: "templates",
    FAVORITES: "favorites",
    DYNAMIC_ELEMENTS: "dynamicElements",
    TEMPLATE_V2: "templateV2",
}

/**
 * Creates a navigation configuration for the "Banco de Precios" application.
 *
 * @param {string} isActivePath - The path that should be marked as active.
 *                                Possible values are 'myBank', 'bank', 'templates',
 *                                'dynamicElements', 'templateV2', or 'favorites'.
 * @param {boolean} isActiveDropdown - Indicates whether the dropdown component should be active.
 * @param {number} key - A unique key for the dropdown component to ensure correct rendering.
 *
 * @returns {Object} An object containing the navigation paths and the dropdown component:
 *                   - paths: An array of navigation items.
 *                   - options2: A React component for the dropdown menu.
 */
const createBancoDePreciosNav = (isActivePath, isActiveDropdown, key) => ({
    paths: [
      {
        label: i18n.t("Mi banco de precios"),
        to: "/bancodeprecios",
        isActive: isActivePath === BPNavPaths.MY_BANK,
        disabled: false,
      },
      {
        label: i18n.t("Banco de precios"),
        to: "/bancodeprecios",
        isActive: isActivePath === BPNavPaths.BANK,
        disabled: false,
        imComponent: true,
      },
      {
        label: i18n.t("Plantillas"),
        to: "/bancodeprecios/templates",
        isActive: isActivePath === BPNavPaths.TEMPLATES,
        disabled: false,
      },
      {
        label: i18n.t('dynamicElements'),
        to: "/bancodeprecios/dynamic-element",
        isActive: isActivePath === BPNavPaths.DYNAMIC_ELEMENTS,
        disabled: false,
      },
      /*
        {
          label: i18n.t('Favoritos'),
          to: '/bancodeprecios/favorites/myBP',
          isActive: isActivePath === 'favoritos',
          disabled: false,
        },
        */
    ],
    options2: <DropdownSelectBank isActive={isActiveDropdown} key={key} />,
  })


  export const BancoDePreciosNav = createBancoDePreciosNav(
    BPNavPaths.MY_BANK,
    false,
    1
  )
  
  export const BancoDePreciosNavCype = createBancoDePreciosNav(
    BPNavPaths.BANK,
    true,
    2
  )
  
  export const BancoDePreciosNavTemplate = createBancoDePreciosNav(
    BPNavPaths.TEMPLATES,
    false,
    3
  )
  
  export const BancoDePreciosNavFavorites = createBancoDePreciosNav(
    BPNavPaths.FAVORITES,
    false,
    4
  )
  
  export const BancoDePreciosDynamicElements = createBancoDePreciosNav(
    BPNavPaths.DYNAMIC_ELEMENTS,
    false,
    5
  )
