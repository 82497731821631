import {
  ADD_DYNAMIC_ELEMENT,
  DELETE_DYNAMIC_ELEMENT,
  EDIT_DYNAMIC_ELEMENT,
  ON_UPDATE,
  SET_ALL_DYNAMIC_ELEMENTS,
  SET_ALL_DYNAMIC_ELEMENTS_TO_INITIAL_STATE,
  SET_ELEMENT_TO_DELETE,
  SET_IS_HOW_TO_ADD_MODAL_OPEN,
  SET_LOADING,
  SET_OPEN_CONFIRM_DELETE_MODAL,
  SET_PARTS_RELATED_TO_ELEMENT_TO_DELETE,
} from "context/types"
import { DynamicElementManager } from "./DynamicElementsState"

const DynamicElementsReducer = (state, action) => {
  switch (action.type) {
    case ON_UPDATE: {
      return {
        ...state,
        updating: true,
      }
    }

    case SET_LOADING: {
      const isLoading = action.payload !== undefined ? action.payload : true

      return {
        ...state,
        loading: isLoading,
      }
    }

    case SET_ALL_DYNAMIC_ELEMENTS: {
      const newState = state.addDynamicElements(action.payload.data)
      newState.loading = false
      return newState
    }

    case SET_ALL_DYNAMIC_ELEMENTS_TO_INITIAL_STATE: {
      state.addDynamicElementsToInitialState()
      const newState = new DynamicElementManager(
        [],
        state.updating,
        state.loading,
        state.dispatches,
        state.deleteElement,
        state.isHowToAddModalOpen
      )
      return newState
    }

    case ADD_DYNAMIC_ELEMENT: {
      state.addDynamicElement(action.payload.element)
      return {
        ...state,
        loading: false,
      }
    }

    case EDIT_DYNAMIC_ELEMENT: {
      state.editElement(action.payload.element)
      return {
        ...state,
        loading: false,
      }
    }

    case DELETE_DYNAMIC_ELEMENT: {
      const newData = state.deleteDynamicElement(action.payload.element)
      return {
        ...state,
        dynamicElements: newData,
        loading: false,
      }
    }

    case SET_OPEN_CONFIRM_DELETE_MODAL: {
      return {
        ...state,
        deleteElement: {
          ...state.deleteElement,
          openConfirmModal: action.payload,
        },
      }
    }

    case SET_ELEMENT_TO_DELETE: {
      return {
        ...state,
        deleteElement: {
          ...state.deleteElement,
          elementToDelete: action.payload,
        },
      }
    }

    case SET_IS_HOW_TO_ADD_MODAL_OPEN: {
      return {
        ...state,
        isHowToAddModalOpen: action.payload,
      }
    }

    case SET_PARTS_RELATED_TO_ELEMENT_TO_DELETE: {
      return {
        ...state,
        deleteElement: {
          ...state.deleteElement,
          partsRelated: action.payload,
        },
      }
    }

    default: {
      return state
    }
  }
}

export default DynamicElementsReducer
