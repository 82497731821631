import React, { useMemo } from "react"
import i18n from "i18n"
import { itemTypesWithIcons, UnityEnum } from "constants/unity"
import DynamicElementEditAction from "components/DynamicElements/DynamicElementEditAction"
import DynamicElementDeleteAction from "components/DynamicElements/DynamicElementDeleteAction"
import { Typography, MenuItem, Select } from "@mui/material"

const renderTypeCell = (params, itemTypesWithIcons) => {
  const selectedOption = itemTypesWithIcons.find(
    option => option.id === params.value
  )
  return (
    <>
      {selectedOption ? selectedOption.icon : <Typography>Unknown</Typography>}
    </>
  )
}

const renderEditCell = (params, options) => (
  <Select
    value={params.value}
    onChange={event =>
      params.api.setEditCellValue({
        id: params.id,
        field: params.field,
        value: event.target.value,
      })
    }
    fullWidth
  >
    {options.map(option => (
      <MenuItem key={option.id || option} value={option.id || option}>
        {option.icon || option}
        {option.type || ""}
      </MenuItem>
    ))}
  </Select>
)

export const useColumns = (apiRef, columnWidths) => {
  return useMemo(
    () => [
      { field: "itemId", headerName: "ID" },
      {
        field: "code",
        headerName: i18n.t("dynamicElement.columns.code"),
        width: 100,
        editable: !apiRef,
      },
      {
        field: "type",
        headerName: i18n.t("dynamicElement.columns.type"),
        width: columnWidths.type,
        editable: !apiRef,
        renderCell: params => renderTypeCell(params, itemTypesWithIcons),
        renderEditCell: params => renderEditCell(params, itemTypesWithIcons),
      },
      {
        field: "description",
        headerName: i18n.t("dynamicElement.columns.description"),
        width: 320,
        editable: !apiRef,
        filterable: true,
      },
      {
        field: "unity",
        headerName: i18n.t("dynamicElement.columns.unity"),
        hide: true,
        type: "singleSelect",
        valueOptions: Object.values(UnityEnum),
        width: 90,
        editable: !apiRef,
      },
      {
        field: "price",
        headerName: i18n.t("dynamicElement.columns.price"),
        width: 100,
        type: "string",
        editable: !apiRef,
      },
      {
        field: "actions",
        headerName: "",
        type: "actions",
        width: 200,
        getActions: params => [
          <DynamicElementEditAction
            key={`save-edit-${params.id}`}
            label="Guardar cambios"
            params={params}
            showInMenu={false}
          />,
          <DynamicElementDeleteAction
            key={`delete-${params.id}`}
            label="Eliminar"
            params={params}
            showInMenu={false}
          />,
        ],
      },
    ],
    [columnWidths.type]
  )
}
