import React, { useState, useContext, useEffect, useCallback } from "react"
import { Auth, API } from "aws-amplify"
import PropTypes from "prop-types"
import { useLocation } from "react-router-dom/cjs/react-router-dom"
import AuthContext from "../auth/authContext"
import SubscriptionContext from "./subscriptionContext"
import ModalExpireFreeTrial from "components/UserView/ModalExpireFreeTrial"
import * as amplitude from "@amplitude/analytics-browser"

const SubscriptionProvider = ({ children }) => {
  const { user } = useContext(AuthContext)
  const [subscriptionState, setSubscriptionState] = useState(null)
  const [isTrial, setIsTrial] = useState(false)
  const [noSubscription, setNoSubscription] = useState(false)
  const [daysLeft, setDaysLeft] = useState(30)
  const [showTopAdviceBar, setShowTopAdviceBar] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [hideSidebar, setHideSidebar] = useState(false)

  const location = useLocation()
  const isSubscriptionPath = location.pathname === "/subscription"

  const getAuthHeaders = useCallback(async () => {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken()
    return {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    }
  }, [])

  const updateTrialInfo = useCallback(() => {
    if (!subscriptionState || !isTrial) return

    const subscription = subscriptionState.subscriptions?.[0]
    if (subscription?.status === "in_trial" && subscription.trial_end) {
      const daysRemaining = Math.floor(
        (subscription.trial_end - Math.floor(Date.now() / 1000)) / 86400
      )
      setDaysLeft(daysRemaining)
    }
  }, [subscriptionState, isTrial])

  useEffect(() => {
    setShowTopAdviceBar(daysLeft < 30)
  }, [daysLeft])

  useEffect(() => {
    if (subscriptionState?.subscriptions?.length > 0) {
      const [subscription] = subscriptionState.subscriptions
      setIsTrial(subscription.status === "in_trial")
      setNoSubscription(false)
    } else {
      setIsTrial(false)
      setNoSubscription(true)
    }
    setLoading(false)
  }, [subscriptionState])

  const fetchSubscriptionData = useCallback(async () => {
    setLoading(true)
    const config = { headers: await getAuthHeaders() }
    const path = "/chargebee/user/active_subscription"
    const query = `user_id=${user.sub}`

    try {
      const response = await API.get("CORE", `${path}?${query}`, config)
      setSubscriptionState(response)
    } catch (error) {
      console.error(`Error fetching subscription data: ${error}`)
      amplitude.track({
        event_type: "[subscription] fetch failed",
        event_properties: {
          user_id: user.sub,
        },
      })
      setLoading(false)
    }
  }, [user])

  useEffect(() => {
    if (user && !loading) {
      fetchSubscriptionData()
    }
  }, [user, fetchSubscriptionData])

  useEffect(() => {
    updateTrialInfo()
  }, [updateTrialInfo])

  useEffect(() => {
    setShowModal(!isSubscriptionPath && isTrial && daysLeft <= 0)
    setHideSidebar(isSubscriptionPath && isTrial && daysLeft <= 0)
  }, [isSubscriptionPath, isTrial, daysLeft])

  return (
    <SubscriptionContext.Provider
      value={{
        subscriptionState,
        isTrial,
        noSubscription,
        daysLeft,
        showTopAdviceBar,
        setShowTopAdviceBar,
        modalExpireFreeTrial: {
          open: showModal,
          setOpen: setShowModal,
        },
        isSubscriptionPath,
        hideSidebar,
      }}
    >
      <ModalExpireFreeTrial />
      {children}
    </SubscriptionContext.Provider>
  )
}

SubscriptionProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default SubscriptionProvider
