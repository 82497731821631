import PropTypes from "prop-types";
import React, {useContext, useEffect, useState} from "react";
import { Link } from "react-router-dom";
import StarIcon from '@mui/icons-material/Star';
import { useHistory } from 'react-router-dom';
import {Chip, Pagination, PaginationItem, Stack} from '@mui/material';
import {DataGrid, gridPageCountSelector, gridPageSelector, useGridApiContext, useGridSelector} from "@mui/x-data-grid";
import ModalSelectTypeProject from "./ModalSelectTypeProject";
import ModalShowExtraTags from "./ModalShowExtraTags";
import DropdownSettings from "components/Project/Overview/DropdownSettings";
import AuthContext from "context/auth/authContext";
import ProjectTable from "./ProjectTable";
import DashboardDataGrid from "components/Dashboard/DashboardDataGrid";

const listaColores = ['color1', 'color2', 'color3', 'color4', 'color5', 'color6', 'color7', 'color8'];


function avatarCell(params){
    return(
        <>
             {params.value.favourite ?
                 <div id="Estrella" style={{marginTop:'70px', marginLeft:'20px'}}><StarIcon sx={{ color: "#FFD52B", fontSize: 20, zIndex:'9' }}></StarIcon></div>
                 : null }
            <img className="avatar-title rounded-circle bg-light text-danger font-size-16" style={{width: '40px',
                height: '40px', marginRight:'10px',
                backgroundColor: '#FACBCB', color:'#D78D8D'}}
                 src={params.value.projectImage
                 }
                 />
                     
            {params.value.name}
        </>
    )
}

function optionsCell(params){
    return(
        <>
        <div style={{position:'absolute',}}>
                            <DropdownSettings imCard={true} project={params.value.project} imList={true}/>
            </div>
        </>
    )
}







const CardProject = ({ projects, onClickPersonalModal,palabrasFiltered, vista, contactos,  setUploadDB, setmodal_first}) => {
 

    const history = useHistory();
    const [openModal, setOpenModal] = useState(false)
    const [tags, setTags] = useState([])
    const {user, getUser, freeTrialExpired} = useContext(AuthContext);
    const [openExcel, setOpenExcel] = useState(false);
    const [waitModal, setWaitModal] = useState(false);


    useEffect(() => {
        if(!user){
            getUser();
        }
    }, [])
    function encontrarCliente(id){
        let trobat = false
        let i=0
        while(trobat==false && i<contactos.length){
            if(contactos[i].id==id){
                trobat = true;
                return contactos[i].completeName;
            }
            else{
                i = i+1
            }
        }
    }
    const handleUploadDB = () =>{
        setUploadDB(true)
    }



    let rows = []
    let contact = {}
  


    function completeRows (){
        for(let i=0; i<projects.length; i++){
            contact =
                {id: projects[i].projectId,
                    name: projects[i],
                    options: { project: projects[i], index: i},
                    orgAddress: projects[i].orgAddress,
                    client: encontrarCliente(projects[i].client),
                    role: projects[i].tags.map((x, i) => {
                        return {
                          role: x,
                          color: listaColores[i],
                        }
                      }),
                    fecha: projects[i].createdAt.substring(0,19),

                }
            rows.push(contact)
        }}
    completeRows()

    const [modalNew, setModalNew] = useState(false)

    if(vista==0){
        return (
            <React.Fragment>
     
                {projects.map((project, index) => (
                    
                     (project.name.toLowerCase().includes(palabrasFiltered.toLowerCase()) || project.orgAddress.toLowerCase().includes(palabrasFiltered.toLowerCase())) &&
                    <div id="projectOverflow" key={(project.projectId)+index} style={{position:'relative'}}>
                    
                        <div className="CardProjects">
                        <Link className="text-dark"
                                                  to={`/budgeting/${project.projectId}`}
                                    
                                            >
                            <div id="CardContent" style={{height:'100%', minHeight:'122px'}} >
                         
                                <div className="ImgProyect" style={{borderRadius:'100px'}}>
                               
                                   {project.favourite ? 
                                    <div id="Estrella"><StarIcon sx={{ color: "#FFD52B", fontSize: 30 }}></StarIcon></div>
                                    :null }
                                    
                                    
                                        
                    <img src={project.projectImage}  style={{height:'90px', width:'90px', marginTop:'-5px', maxWidth:'90px', marginLeft:'-5px', borderRadius:'100px'}} />
        
            
                                </div>
                                
                                <div id="CenterContentProyectCard">
                                    <div className="flex-grow-1 overflow-hidden">
                                        <div id="AddProyecto">
                                                {project.name}
                                        </div>
                                        <p className="DescriptionText">{project.orgAddress}</p>
                                    </div>
                              
                                </div>
                              
                                
                                
                            </div>
                            </Link>
                        </div>
                        <div className="DropdownContainer" style={{position:'absolute', right:'20px', top: project.favourite? project.orgAddress?.length>0? '85px' : '85px' : '85px', height:'0px'}}>
                            <DropdownSettings imCard={true} project={project}/>
                            </div>
                    </div>
                    
                ))}

                {
                    /*
                    user ? 
                        user.freeTrialExpired || user['custom:role'] === 'WORKER' ?
                            null
                        :
                        <div id="projectOverflow" onClick={handleOpenNew} style={{cursor:'pointer'}}>
                    <div className="CardProjects">
                        <div id="CardContent" style={{height:'100%', minHeight:'122px'}} >
                            <div className="ImgProyect">                 
                                <img src={addProject}  style={{height:'90px', width:'90px', marginTop:'-5px', maxWidth:'90px', marginLeft:'-5px'}} />
                            </div>
                            <div id="CenterContentProyectCard">
                                <div className="flex-grow-1 overflow-hidden"  style={{marginLeft:'15px'}}>
                                    <div id="AddProyecto">
                                            Nuevo Proyecto
                                    </div>
                                </div>
                          
                            </div>
                        </div>

                    </div>
                </div>
                :null
                */
                }

            
                
            <ModalSelectTypeProject modalNew={modalNew} setModalNew={setModalNew} setmodal_first={setmodal_first} onClickPersonalModal={onClickPersonalModal}/>
            </React.Fragment>
        );}
    if(vista==1){
        return (
        <div style={{position:'relative', alignItems:'center',justifyContent:'center', display:'flex', marginRight:'20px', marginLeft:'50px', width:'95%'}}>
                {/* <ProjectTable projects={projects} contactos={contactos} palabrasFiltered={palabrasFiltered}/> */}
             <DashboardDataGrid projects={projects} />
               
                <ModalSelectTypeProject modalNew={modalNew} setModalNew={setModalNew} setmodal_first={setmodal_first} onClickPersonalModal={onClickPersonalModal}/>
                <ModalShowExtraTags open={openModal} setOpen={setOpenModal} tags={tags}/>
            </div>
        )
    }
    // if(vista==2){
    //     return (
    //     <div style={{position:'relative', alignItems:'center',justifyContent:'center', display:'flex', marginRight:'20px', marginLeft:'50px', width:'95%'}}>
    //          <DashboardDataGrid projects={projects} />
    //     </div>
    //     )
    // }
    

};

CardProject.propTypes = {
    projects: PropTypes.array,
    onClickPersonalModal: PropTypes.func,
    vista: PropTypes.number,
    contactos: PropTypes.array,
    setUploadDB : PropTypes.func.isRequired,
    setmodal_first: PropTypes.func.isRequired,
    palabrasFiltered: PropTypes.string,

};

export default CardProject;