import React, { useReducer } from "react"
import PropTypes, { element } from "prop-types"
import DynamicElementsContext from "./DynamicElementsContext"
import DynamicElementsReducer from "./DynamicElementsReducer"
import {
  ADD_DYNAMIC_ELEMENT,
  DELETE_DYNAMIC_ELEMENT,
  EDIT_DYNAMIC_ELEMENT,
  SET_ALL_DYNAMIC_ELEMENTS,
  SET_ALL_DYNAMIC_ELEMENTS_TO_INITIAL_STATE,
  SET_ELEMENT_TO_DELETE,
  SET_IS_HOW_TO_ADD_MODAL_OPEN,
  SET_LOADING,
  SET_OPEN_CONFIRM_DELETE_MODAL,
  SET_PARTS_RELATED_TO_ELEMENT_TO_DELETE,
} from "context/types"
import { API, Auth } from "aws-amplify"

const emptyFn = () => {}
const CORE = "CORE"

export class DynamicElement {
  constructor({ itemId, type, description, unity, price, code }) {
    this.itemId = itemId
    this.type = type
    this.description = description
    this.unity = unity
    this.price = price
    this.code = code
  }
}

export class DynamicElementManager {
  constructor(
    dynamicElements = [],
    updating = false,
    loading = false,
    dispatches = {
      addElement: emptyFn,
      getDynamicElements: emptyFn,
      addElement: emptyFn,
      deleteElement: emptyFn,
      editElement: emptyFn,
      setElementToDelete: emptyFn,
      setConfirmDeleteModal: emptyFn,
      setIsHowToAddModalOpen: emptyFn,
      getDynamicElementsConvertFromBank: emptyFn,
      setDynamicElementsToInitialState: emptyFn,
      setPartsRelatedToElementToDelete: emptyFn,
    },
    deleteElement = {
      openConfirmModal: false,
      elementToDelete: null,
      partsRelated: null,
    },
    isHowToAddModalOpen = false
  ) {
    this.dynamicElements = dynamicElements
    this.updating = updating
    this.loading = loading
    this.dispatches = dispatches
    this.deleteElement = deleteElement
    this.isHowToAddModalOpen = isHowToAddModalOpen
  }

  addDynamicElement = element => {
    this.dynamicElements.push(element)
  }

  addDynamicElements = elements => {
    elements.forEach(element => {
      this.dynamicElements.push(element)
    })
    return this
  }

  addDynamicElementsToInitialState = () => {
    this.dynamicElements = []
  }

  deleteDynamicElement = element => {
    const x = this.dynamicElements.filter(el => el.itemId !== element.itemId)
    this.dynamicElements = x
    return this.dynamicElements
  }

  editElement = element => {
    const dataEdited = this.dynamicElements.map(e => {
      if (e.itemId !== element.itemId) {
        return e
      }

      e.type = element.type
      e.description = element.description
      e.unity = element.unity
      e.price = element.price
      e.code = element.code

      return e
    })

    this.dynamicElements = dataEdited

    return dataEdited
  }
}

const initialState = new DynamicElementManager()

const DynamicElementsState = ({ children }) => {
  const [state, dispatch] = useReducer(DynamicElementsReducer, initialState)

  const setLoading = isLoading => {
    dispatch({
      type: SET_LOADING,
      payload: isLoading,
    })
  }

  const getCommonConfig = async () => {
    const generalConfig = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        "Access-Control-Allow-Origin": "*",
      },
    }
    return generalConfig
  }

  const getDynamicElements = async poolId => {
    if (!poolId) {
      console.error("Invalid poolId provided.")
      return
    }

    let isMounted = true // Flag to avoid state updates on unmounted component

    try {
      setLoading()
      const configurations = await getCommonConfig()
      const respDynamicElements = await API.get(
        CORE,
        `/decompositions/?poolID=${poolId}`,
        configurations
      )

      if (isMounted) {
        dispatch({
          type: SET_ALL_DYNAMIC_ELEMENTS,
          payload: {
            data: respDynamicElements?.map(e => new DynamicElement(e)),
          },
        })
      }
    } catch (error) {
      console.error("Error fetching dynamic elements:", error)
    } finally {
      return () => {
        isMounted = false
      } // Clean up effect
    }
  }
  const getDynamicElementsConvertFromBank = async poolId => {
    if (!poolId) {
      console.error("Invalid poolId provided.")
      return
    }

    let isMounted = true

    try {
      setLoading()
      const configurations = await getCommonConfig()
      const respDynamicElements = await API.post(
        CORE,
        `/decompositions/convert_from_bank?poolID=${poolId}`,
        configurations
      )

      if (isMounted) {
        dispatch({
          type: SET_ALL_DYNAMIC_ELEMENTS_TO_INITIAL_STATE,
        })

        dispatch({
          type: SET_ALL_DYNAMIC_ELEMENTS,
          payload: {
            data: respDynamicElements?.map(e => new DynamicElement(e)),
          },
        })
      }
    } catch (error) {
      console.error("Error fetching dynamic elements:", error)
    } finally {
      return () => {
        isMounted = false
      } // Clean up effect
    }
  }

  const setDynamicElementsToInitialState = () => {
    dispatch({
      type: SET_ALL_DYNAMIC_ELEMENTS_TO_INITIAL_STATE,
    })
  }

  const addElement = async (element, poolId) => {
    try {
      const configurations = await getCommonConfig()
      const body = [
        {
          type: element.type,
          description: element.description,
          unity: element.unity,
          price: element.price,
          code: element.code,
        },
      ]

      const payload = {
        ...configurations,
        body: body,
      }
      const respDynamicElements = await API.post(
        CORE,
        `/decompositions/?poolID=${poolId}`,
        payload
      )

      const id = respDynamicElements[0]
      const newData = new DynamicElement({
        itemId: id,
        type: element.type,
        description: element.description,
        unity: element.unity,
        price: element.price,
        code: element.code,
      })

      dispatch({
        type: ADD_DYNAMIC_ELEMENT,
        payload: {
          element: newData,
        },
      })
      return true //is success
    } catch (error) {
      return false
    }
  }

  const deleteElement = async (element, poolId) => {
    try {
      setLoading(true)

      const configurations = await getCommonConfig()

      const response = await API.del(
        CORE,
        `/decompositions/bank?bankID=${poolId}&items_ids=${element.itemId}`,
        configurations
      )

      if (Object.keys(response).length === 0) {
        dispatch({
          type: DELETE_DYNAMIC_ELEMENT,
          payload: {
            element: element,
          },
        })

        setConfirmDeleteModal(false)
      } else {
        dispatch({
          type: SET_PARTS_RELATED_TO_ELEMENT_TO_DELETE,
          payload: response[element.itemId],
        })
      }

      setLoading(false)

      return true //is success
    } catch (error) {
      return false // is success
    }
  }

  const editElement = async (element, poolId) => {
    try {
      setLoading()

      dispatch({
        type: EDIT_DYNAMIC_ELEMENT,
        payload: {
          element: element,
        },
      })

      const configurations = await getCommonConfig()
      const body = [
        {
          itemId: element.itemId,
          type: element.type,
          description: element.description,
          unity: element.unity,
          price: element.price,
          code: element.code,
        },
      ]

      const payload = {
        ...configurations,
        body: body,
      }

      await API.put(CORE, `/decompositions/bank?bankID=${poolId}`, payload)

      return true //is success
    } catch (error) {
      return false // is success
    }
  }

  const setConfirmDeleteModal = isModalOpen => {
    dispatch({
      type: SET_OPEN_CONFIRM_DELETE_MODAL,
      payload: isModalOpen,
    })
  }

  const setElementToDelete = element => {
    dispatch({
      type: SET_ELEMENT_TO_DELETE,
      payload: element,
    })
  }

  const setIsHowToAddModalOpen = isModalOpen => {
    dispatch({
      type: SET_IS_HOW_TO_ADD_MODAL_OPEN,
      payload: isModalOpen,
    })
  }
  const setPartsRelatedToElementToDelete = partsRelated => {
    dispatch({
      type: SET_PARTS_RELATED_TO_ELEMENT_TO_DELETE,
      payload: partsRelated,
    })
  }

  const contextValue = new DynamicElementManager(
    state.dynamicElements,
    state.updating,
    state.loading,
    {
      ...state.dispatches,
      setLoading,
      getDynamicElements,
      addElement,
      deleteElement,
      editElement,
      setElementToDelete,
      setConfirmDeleteModal,
      setIsHowToAddModalOpen,
      getDynamicElementsConvertFromBank,
      setDynamicElementsToInitialState,
      setPartsRelatedToElementToDelete,
    },
    {
      openConfirmModal: state.deleteElement.openConfirmModal,
      elementToDelete: state.deleteElement.elementToDelete,
      partsRelated: state.deleteElement.partsRelated,
    },
    state.isHowToAddModalOpen
  )

  return (
    <DynamicElementsContext.Provider value={contextValue}>
      {children}
    </DynamicElementsContext.Provider>
  )
}

DynamicElementsState.propTypes = {
  children: PropTypes.any.isRequired,
}

export default DynamicElementsState
