import DynamicElementForm from "components/DynamicElements/DynamicElementForm"
import React, { useContext, useEffect } from "react"
import AuthContext from "context/auth/authContext"
import DynamicElementList from "components/DynamicElements/DynamicElementList"
import HowToAddDynamicElementsModal from "components/DynamicElements/HowToAddDynamicElementsModal"
import DynamicElementsContext from "context/dynamicElements/DynamicElementsContext"
import { Box, Button } from "@mui/material"
import i18n from "i18n"
import { greyButtonStyle } from "common/buttons"

const DynamicElementsIndex = () => {
  const { dynamicElements, dispatches, loading, isHowToAddModalOpen } =
    useContext(DynamicElementsContext)
  const { user } = useContext(AuthContext)
  const organizationId = user?.["custom:organizationId"] || null

  useEffect(() => {
    dispatches.setIsHowToAddModalOpen(dynamicElements.length === 0)
  }, [dynamicElements, loading])

  const handleOnUpdate = () => {
    dispatches.getDynamicElementsConvertFromBank(organizationId)
  }

  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center"
      style={{
        marginInline: 20,
        marginBlock: 100,
      }}
    >
      {organizationId && (
        <>
          <DynamicElementList
            className={"mb-3"}
            organizationId={organizationId}
          >
            {dynamicElements && (
              <>
                <Box
                  sx={{
                    width: "100%",
                    paddingInline: "5%",
                    marginBottom: 5,
                  }}
                >
                  <DynamicElementForm poolId={organizationId} />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    marginBottom: 1,
                  }}
                >
                  <Button
                    variant="outlined"
                    sx={greyButtonStyle}
                    onClick={handleOnUpdate}
                  >
                    {i18n.t("buttons.update")}
                  </Button>
                </Box>
              </>
            )}
          </DynamicElementList>

          {dynamicElements && !loading && (
            <HowToAddDynamicElementsModal
              open={isHowToAddModalOpen}
              onClose={isOpen => {
                dispatches.setIsHowToAddModalOpen(isOpen)
              }}
              onUpdate={handleOnUpdate}
            />
          )}
        </>
      )}
    </div>
  )
}

export default DynamicElementsIndex
