import i18n from "i18n"

const DataGridLocaleText = () => {

  const GRID_DEFAULT_LOCALE_TEXT = {
    // Root
    noRowsLabel: i18n.t("datagrid.root.noRowsLabel"),
    noResultsOverlayLabel: i18n.t("datagrid.root.noResultsOverlayLabel"),

    // Density selector toolbar button text
    toolbarDensity: i18n.t("datagrid.toolbar.density"),
    toolbarDensityLabel: i18n.t("datagrid.toolbar.densityLabel"),
    toolbarDensityCompact: i18n.t("datagrid.toolbar.densityCompact"),
    toolbarDensityStandard: i18n.t("datagrid.toolbar.densityStandard"),
    toolbarDensityComfortable: i18n.t("datagrid.toolbar.densityComfortable"),

    // Columns selector toolbar button text
    toolbarColumns: i18n.t("datagrid.toolbar.columns"),
    toolbarColumnsLabel: i18n.t("datagrid.toolbar.columnsLabel"),

    // Filters toolbar button text
    toolbarFilters: i18n.t("datagrid.toolbar.filters"),
    toolbarFiltersLabel: i18n.t("datagrid.toolbar.filtersLabel"),
    toolbarFiltersTooltipHide: i18n.t("datagrid.toolbar.filtersTooltipHide"),
    toolbarFiltersTooltipShow: i18n.t("datagrid.toolbar.filtersTooltipShow"),
    toolbarFiltersTooltipActive: count =>
      i18n.t("datagrid.toolbar.filtersTooltipActive", { count }),

    // Quick filter toolbar field
    toolbarQuickFilterPlaceholder: i18n.t("datagrid.toolbar.quickFilterPlaceholder"),
    toolbarQuickFilterLabel: i18n.t("datagrid.toolbar.quickFilterLabel"),
    toolbarQuickFilterDeleteIconLabel: i18n.t(
      "datagrid.toolbar.quickFilterDeleteIconLabel"
    ),

    // Export selector toolbar button text
    toolbarExport: i18n.t("datagrid.toolbar.export"),
    toolbarExportLabel: i18n.t("datagrid.toolbar.exportLabel"),
    toolbarExportCSV: i18n.t("datagrid.toolbar.exportCSV"),
    toolbarExportPrint: i18n.t("datagrid.toolbar.exportPrint"),
    toolbarExportExcel: i18n.t("datagrid.toolbar.exportExcel"),

    // Columns management text
    columnsManagementSearchTitle: i18n.t("datagrid.columns.managementSearchTitle"),
    columnsManagementNoColumns: i18n.t("datagrid.columns.managementNoColumns"),
    columnsManagementShowHideAllText: i18n.t(
      "datagrid.columns.managementShowHideAllText"
    ),
    columnsManagementReset: i18n.t("datagrid.columns.managementReset"),

    // Filter panel text
    filterPanelAddFilter: i18n.t("datagrid.filter.panelAddFilter"),
    filterPanelRemoveAll: i18n.t("datagrid.filter.panelRemoveAll"),
    filterPanelDeleteIconLabel: i18n.t("datagrid.filter.panelDeleteIconLabel"),
    filterPanelLogicOperator: i18n.t("datagrid.filter.panelLogicOperator"),
    filterPanelOperator: i18n.t("datagrid.filter.panelOperator"),
    filterPanelOperatorAnd: i18n.t("datagrid.filter.panelOperatorAnd"),
    filterPanelOperatorOr: i18n.t("datagrid.filter.panelOperatorOr"),
    filterPanelColumns: i18n.t("datagrid.filter.panelColumns"),
    filterPanelInputLabel: i18n.t("datagrid.filter.panelInputLabel"),
    filterPanelInputPlaceholder: i18n.t("datagrid.filter.panelInputPlaceholder"),

    // Filter operators text
    filterOperatorContains: i18n.t("datagrid.filter.operatorContains"),
    filterOperatorDoesNotContain: i18n.t("datagrid.filter.operatorDoesNotContain"),
    filterOperatorEquals: i18n.t("datagrid.filter.operatorEquals"),
    filterOperatorDoesNotEqual: i18n.t("datagrid.filter.operatorDoesNotEqual"),
    filterOperatorStartsWith: i18n.t("datagrid.filter.operatorStartsWith"),
    filterOperatorEndsWith: i18n.t("datagrid.filter.operatorEndsWith"),
    filterOperatorIs: i18n.t("datagrid.filter.operatorIs"),
    filterOperatorNot: i18n.t("datagrid.filter.operatorNot"),
    filterOperatorAfter: i18n.t("datagrid.filter.operatorAfter"),
    filterOperatorOnOrAfter: i18n.t("datagrid.filter.operatorOnOrAfter"),
    filterOperatorBefore: i18n.t("datagrid.filter.operatorBefore"),
    filterOperatorOnOrBefore: i18n.t("datagrid.filter.operatorOnOrBefore"),
    filterOperatorIsEmpty: i18n.t("datagrid.filter.operatorIsEmpty"),
    filterOperatorIsNotEmpty: i18n.t("datagrid.filter.operatorIsNotEmpty"),
    filterOperatorIsAnyOf: i18n.t("datagrid.filter.operatorIsAnyOf"),
    "filterOperator=": i18n.t("datagrid.filter.operatorEqualsSign"),
    "filterOperator!=": i18n.t("datagrid.filter.operatorNotEqualsSign"),
    "filterOperator>": i18n.t("datagrid.filter.operatorGreaterThan"),
    "filterOperator>=": i18n.t("datagrid.filter.operatorGreaterThanOrEqual"),
    "filterOperator<": i18n.t("datagrid.filter.operatorLessThan"),
    "filterOperator<=": i18n.t("datagrid.filter.operatorLessThanOrEqual"),

    // Header filter operators text
    headerFilterOperatorContains: i18n.t("datagrid.filter.headerOperatorContains"),
    headerFilterOperatorDoesNotContain: i18n.t(
      "datagrid.filter.headerOperatorDoesNotContain"
    ),
    headerFilterOperatorEquals: i18n.t("datagrid.filter.headerOperatorEquals"),
    headerFilterOperatorDoesNotEqual: i18n.t(
      "datagrid.filter.headerOperatorDoesNotEqual"
    ),
    headerFilterOperatorStartsWith: i18n.t(
      "datagrid.filter.headerOperatorStartsWith"
    ),
    headerFilterOperatorEndsWith: i18n.t("datagrid.filter.headerOperatorEndsWith"),
    headerFilterOperatorIs: i18n.t("datagrid.filter.headerOperatorIs"),
    headerFilterOperatorNot: i18n.t("datagrid.filter.headerOperatorNot"),
    headerFilterOperatorAfter: i18n.t("datagrid.filter.headerOperatorAfter"),
    headerFilterOperatorOnOrAfter: i18n.t("datagrid.filter.headerOperatorOnOrAfter"),
    headerFilterOperatorBefore: i18n.t("datagrid.filter.headerOperatorBefore"),
    headerFilterOperatorOnOrBefore: i18n.t(
      "datagrid.filter.headerOperatorOnOrBefore"
    ),
    headerFilterOperatorIsEmpty: i18n.t("datagrid.filter.headerOperatorIsEmpty"),
    headerFilterOperatorIsNotEmpty: i18n.t(
      "datagrid.filter.headerOperatorIsNotEmpty"
    ),
    headerFilterOperatorIsAnyOf: i18n.t("datagrid.filter.headerOperatorIsAnyOf"),
    "headerFilterOperator=": i18n.t("datagrid.filter.headerOperatorEqualsSign"),
    "headerFilterOperator!=": i18n.t("datagrid.filter.headerOperatorNotEqualsSign"),
    "headerFilterOperator>": i18n.t("datagrid.filter.headerOperatorGreaterThan"),
    "headerFilterOperator>=": i18n.t(
      "datagrid.filter.headerOperatorGreaterThanOrEqual"
    ),
    "headerFilterOperator<": i18n.t("datagrid.filter.headerOperatorLessThan"),
    "headerFilterOperator<=": i18n.t(
      "datagrid.filter.headerOperatorLessThanOrEqual"
    ),

    // Filter values text
    filterValueAny: i18n.t("datagrid.filter.valueAny"),
    filterValueTrue: i18n.t("datagrid.filter.valueTrue"),
    filterValueFalse: i18n.t("datagrid.filter.valueFalse"),

    // Column menu text
    columnMenuLabel: i18n.t("datagrid.columns.menuLabel"),
    columnMenuShowColumns: i18n.t("datagrid.columns.menuShowColumns"),
    columnMenuManageColumns: i18n.t("datagrid.columns.menuManageColumns"),
    columnMenuFilter: i18n.t("datagrid.columns.menuFilter"),
    columnMenuHideColumn: i18n.t("datagrid.columns.menuHideColumn"),
    columnMenuUnsort: i18n.t("datagrid.columns.menuUnsort"),
    columnMenuSortAsc: i18n.t("datagrid.columns.menuSortAsc"),
    columnMenuSortDesc: i18n.t("datagrid.columns.menuSortDesc"),

    // Column header text
    columnHeaderFiltersTooltipActive: count =>
      i18n.t("datagrid.columns.headerFiltersTooltipActive", { count }),
    columnHeaderFiltersLabel: i18n.t("datagrid.columns.headerFiltersLabel"),
    columnHeaderSortIconLabel: i18n.t("datagrid.columns.headerSortIconLabel"),

    // Rows selected footer text
    footerRowSelected: count =>
      i18n.t("datagrid.footer.rowSelected", { count: count.toLocaleString() }),

    // Total row amount footer text
    footerTotalRows: i18n.t("datagrid.footer.totalRows"),

    // Total visible row amount footer text
    footerTotalVisibleRows: (visibleCount, totalCount) =>
      i18n.t("datagrid.footer.totalVisibleRows", {
        visibleCount: visibleCount.toLocaleString(),
        totalCount: totalCount.toLocaleString(),
      }),

    // Checkbox selection text
    checkboxSelectionHeaderName: i18n.t("datagrid.checkbox.selectionHeaderName"),
    checkboxSelectionSelectAllRows: i18n.t(
      "datagrid.checkbox.selectionSelectAllRows"
    ),
    checkboxSelectionUnselectAllRows: i18n.t(
      "datagrid.checkbox.selectionUnselectAllRows"
    ),
    checkboxSelectionSelectRow: i18n.t("datagrid.checkbox.selectionSelectRow"),
    checkboxSelectionUnselectRow: i18n.t("datagrid.checkbox.selectionUnselectRow"),

    // Boolean cell text
    booleanCellTrueLabel: i18n.t("datagrid.booleanCell.trueLabel"),
    booleanCellFalseLabel: i18n.t("datagrid.booleanCell.falseLabel"),

    // Actions cell more text
    actionsCellMore: i18n.t("datagrid.actionsCell.more"),

    // Column pinning text
    pinToLeft: i18n.t("datagrid.pinning.pinToLeft"),
    pinToRight: i18n.t("datagrid.pinning.pinToRight"),
    unpin: i18n.t("datagrid.pinning.unpin"),

    // Tree Data
    treeDataGroupingHeaderName: i18n.t("datagrid.treeData.groupingHeaderName"),
    treeDataExpand: i18n.t("datagrid.treeData.expand"),
    treeDataCollapse: i18n.t("datagrid.treeData.collapse"),

    // Grouping columns
    groupingColumnHeaderName: i18n.t("datagrid.grouping.columnHeaderName"),
    groupColumn: name => i18n.t("datagrid.grouping.groupColumn", { name }),
    unGroupColumn: name => i18n.t("datagrid.grouping.unGroupColumn", { name }),

    // Master/detail
    detailPanelToggle: i18n.t("datagrid.masterDetail.toggle"),
    expandDetailPanel: i18n.t("datagrid.masterDetail.expand"),
    collapseDetailPanel: i18n.t("datagrid.masterDetail.collapse"),

    // Row reordering text
    rowReorderingHeaderName: i18n.t("datagrid.rowReordering.headerName"),

    // Aggregation
    aggregationMenuItemHeader: i18n.t("datagrid.aggregation.menuItemHeader"),
    aggregationFunctionLabelSum: i18n.t("datagrid.aggregation.functionLabelSum"),
    aggregationFunctionLabelAvg: i18n.t("datagrid.aggregation.functionLabelAvg"),
    aggregationFunctionLabelMin: i18n.t("datagrid.aggregation.functionLabelMin"),
    aggregationFunctionLabelMax: i18n.t("datagrid.aggregation.functionLabelMax"),
    aggregationFunctionLabelSize: i18n.t("datagrid.aggregation.functionLabelSize"),
  }

  return GRID_DEFAULT_LOCALE_TEXT
}

export default DataGridLocaleText