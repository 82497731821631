import PropTypes from "prop-types"
import React, { useContext, useEffect } from "react"
import { withRouter } from "react-router-dom"
import Sidebar from "./Sidebar"
import MainNavbar from "components/Navbars/MainNavbar"
import AuthContext from "context/auth/authContext"
import SubscriptionContext from "context/subscription/subscriptionContext"
import Budget2023Context from "context/budget2023/Budget2023Context"

const Layout = props => {
  const { detectChange } = useContext(Budget2023Context)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [sidebarMini, setSidebarMini] = React.useState(true)
  const handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      setSidebarMini(false)
      detectChange()
      document.body.classList = ""
    } else {
      setSidebarMini(true)
      detectChange()
      document.body.classList.toggle("sidebar-mini")
    }
  }

  const { user, getUser } = useContext(AuthContext)
  const { showTopAdviceBar, hideSidebar } = useContext(SubscriptionContext)

  useEffect(() => {
    if (!user) {
      getUser()
    }
  }, [])

  return (
    <React.Fragment>
      <div>
        <div className="main-panel" style={{ padding: "0" }}>
          <MainNavbar
            navContent={props.navContent}
            handleMiniClick={handleMiniClick}
            sidebarMini={sidebarMini}
          />
        </div>

        {!hideSidebar && (
          <Sidebar
            navContent={props.navContent}
            handleMiniClick={handleMiniClick}
            sidebarMini={sidebarMini}
          />
        )}

        <div
          className="main-content"
          style={{
            marginTop: user
              ? (user["custom:subscription_plan"] == "FREE_TRIAL" ||
                  user["custom:subscription_status"] == "cancelled") &&
                showTopAdviceBar
                ? "40px"
                : "0px"
              : "0px",
          }}
        >
          {props.children}
        </div>
      </div>
    </React.Fragment>
  )
}

Layout.propTypes = {
  changeLayoutWidth: PropTypes.func,
  changeSidebarTheme: PropTypes.func,
  changeSidebarThemeImage: PropTypes.func,
  changeSidebarType: PropTypes.func,
  changeTopbarTheme: PropTypes.func,
  children: PropTypes.any,
  isPreloader: PropTypes.any,
  layoutWidth: PropTypes.any,
  leftSideBarTheme: PropTypes.any,
  leftSideBarThemeImage: PropTypes.any,
  leftSideBarType: PropTypes.any,
  location: PropTypes.object,
  showRightSidebar: PropTypes.any,
  topbarTheme: PropTypes.any,
  navContent: PropTypes.any,
}

export default withRouter(Layout)
