import React, { useEffect, useState, useContext} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import { Modal} from "@mui/material";
import i18n from 'i18n';
import { HelveticaBold } from 'common/typographies';
import { YellowButton } from 'common/buttons';
import nico from 'assets/images/avatar/nico.png';
import creditCardIcon from 'assets/images/creditCards/creditCardIcon.png';
import { BlueButton } from 'common/containers';
import { useHistory } from 'react-router-dom';
import AuthContext from 'context/auth/authContext';
import SubscriptionContext from 'context/subscription/subscriptionContext';

function getModalStyle() {
    const top = 50 ;
    const left = 50;
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };

}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: '750px',
        height: '450px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: 6,
        maxHeight: 'calc(100vh - 100px)',
        overflowY: 'hidden',
        overflowX:'hidden',
    },
}));

const ModalExpireFreeTrial =()=> {

    const [ modalStyle ] = useState(getModalStyle);
    const classes = useStyles();
    const history = useHistory();
    const {user, getUser} = useContext(AuthContext);
    const {modalExpireFreeTrial} = useContext(SubscriptionContext);

    useEffect(() => {
        if(!user) {
            getUser();
        }
    }, [user]);

    const goToPlanes  =  () => {
        history.push('/subscription')
        history.go(0)
    }


    return(
        <>
            <Modal
                open={modalExpireFreeTrial.open}
                onClose={() => {
                    null
                }}
            >
                <div style={modalStyle} className={classes.paper}>
                <div style={{margin:'10px'}}>
                    <HelveticaBold style={{fontSize:'26px', color:'#414141', marginBottom:'0px', marginTop:'15px'}}>{i18n.t('Hey!!')}</HelveticaBold>
                    <HelveticaBold style={{fontSize:'20px', color:'#414141'}}>{user ? user['custom:subscription_status']=='cancelled' ?i18n.t('Tu suscripcion ha finalizado.') :i18n.t('Tu periodo de prueba ha finalizado.') :i18n.t('Tu periodo de prueba ha finalizado.')}</HelveticaBold>
                    <HelveticaBold style={{fontSize:'14px', color:'#707070', fontWeight:'400'}}>{i18n.t('Nos encanta trabajar con vosotros, por lo que te damos diferentes opciones para seguir trabajando juntos.')}</HelveticaBold>
                    <div className='inline'>
                        <div style={{width:'5px', height:'5px', borderRadius:'50px', backgroundColor:'#8E8E8E', marginTop:'8.5px', marginLeft:'20px', marginRight:'5px'}}></div>
                        <HelveticaBold style={{fontSize:'14px', color:'#707070', fontWeight:'400'}}>{i18n.t('Echa un vistazo a')}</HelveticaBold>
                        <HelveticaBold onClick={()=>{goToPlanes()}} style={{fontSize:'14px', color:'#4461D7', fontWeight:'700', marginLeft:'5px', textDecoration:'underline', cursor:'pointer'}}>{i18n.t('nuestros planes')}</HelveticaBold>
                    </div>
                    <div className='inline' style={{marginTop:'-5px'}}>
                        <div style={{width:'5px', height:'5px', borderRadius:'50px', backgroundColor:'#8E8E8E', marginTop:'8.5px', marginLeft:'20px', marginRight:'5px'}}></div>
                        <HelveticaBold style={{fontSize:'14px', color:'#707070', fontWeight:'400'}}>{i18n.t('Si aún no estás muy convencido y quieres más información, recibe una')}</HelveticaBold>
                    </div>
                    <div className='inline' style={{marginTop:'-18px'}}>
                        <a href='https://calendly.com/planhopper-xvq/planhopper' target='_blank' rel='noreferrer'>
                            <HelveticaBold style={{fontSize:'14px', color:'#4461D7',  marginLeft:'30px', marginRight:'5px', fontWeight:'700', textDecoration:'underline', cursor:'pointer'}}>{i18n.t('consultoria gratuita')}</HelveticaBold>
                        </a>
                        <HelveticaBold style={{fontSize:'14px', color:'#707070', fontWeight:'400'}}>{i18n.t('y sigamos mejorando juntos')}</HelveticaBold>
                    </div>

                    <div className='inline' style={{margin:'30px', marginTop:'20px', marginBottom:'0px'}}>
                        <div style={{position:'relative', justifyContent:'center', width:'50%', display:'flex'}}>
                            <div style={{borderRadius:"50px", width:'95px', height:'95px', justifyContent:'center', backgroundColor:'#FFD52B', display:'flex'}}>
                                <img  onClick={()=>{goToPlanes()}}  src={creditCardIcon} width='58px' height='58px' style={{marginTop:'20px', cursor:'pointer'}}></img>
                            </div>
                        </div>
                        <div style={{position:'relative', justifyContent:'center', width:'50%', display:'flex'}}>
                            <a href='https://calendly.com/planhopper-xvq/planhopper' target='_blank' rel='noreferrer'>
                                <img src={nico} width='95px' height='95px' style={{cursor:'pointer'}}></img>
                            </a>
                        </div>
                    </div>
                    <div className='inline' style={{margin:'30px', marginTop:'20px'}}>
                        <div style={{position:'relative', justifyContent:'center', width:'50%', display:'flex'}}>
                            <YellowButton 
                            data-amplify-analytics-on="click"
                            data-amplify-analytics-name="ChooseYourPlan"
                            data-amplify-analytics-attrs={""}
                            onClick={()=>{goToPlanes()}}  style={{width:'240px', height:'40px', borderRadius:'6px', fontSize:'14px', fontWeight:'700', color:'#414141'}}>{i18n.t('¡Elige tu Plan Ahora!')}</YellowButton>
                        </div>
                        <div style={{position:'relative', justifyContent:'center', width:'50%', display:'flex'}}>
                            <a href='https://calendly.com/planhopper-xvq/planhopper' target='_blank' rel='noreferrer'>
                                <BlueButton style={{width:'240px', height:'40px', borderRadius:'6px', fontSize:'14px', fontWeight:'700', color:'white', textAlign:'center', paddingTop:'9px'}}>{i18n.t('¡Consultoria Gratuita!')}</BlueButton>
                            </a>
                        </div>
                    </div>
                    
                </div>
                </div>
            </Modal>
        </>
    )
}

export default ModalExpireFreeTrial;