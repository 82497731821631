import React, { useContext } from "react"
import PropTypes from "prop-types"
import { Box, Button, Modal } from "@mui/material"
import i18n from "i18n"
import { useGridApiRef } from "@mui/x-data-grid"
import DynamicElementList from "./DynamicElementList"
import AuthContext from "context/auth/authContext"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
}

const SelectDynamicElementsModal = ({
  isOpen,
  onClose,
  setSelectedElements,
  projectId,
}) => {
  const apiRef = useGridApiRef()
  const { user } = useContext(AuthContext)

  const organizationId = projectId
    ? projectId
    : user?.["custom:organizationId"] || null

  const handleSelected = () => {
    setSelectedElements(apiRef.current.getSelectedRows())
  }

  return (
    <Modal
      open={isOpen}
      // onClose={() => onClose(false)}
      aria-labelledby="confirm-delete-title"
      aria-describedby="confirm-delete-description"
    >
      <Box sx={style}>
        <h1>SelectDynamicElementsModal</h1>

        {organizationId && (
          <DynamicElementList
            className={"mb-3"}
            organizationId={organizationId}
            apiRef={apiRef}
            showActions={false}
          />
        )}

        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              onClose(false)
            }}
            sx={{ mr: 1 }}
          >
            {i18n.t("buttons.cancel")}
          </Button>
          <Button variant="contained" color="primary" onClick={handleSelected}>
            {i18n.t("buttons.accept")}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

SelectDynamicElementsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setSelectedElements: PropTypes.func.isRequired,
  projectId: PropTypes.string,
}

export default SelectDynamicElementsModal
