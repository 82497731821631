import { useState, useCallback } from "react"

const INITIAL_TYPE_WIDTH = 70
const EDIT_VIEW_TYPE_WIDTH = 350

export const useColumnWidths = () => {
  const [columnWidths, setColumnWidths] = useState({
    type: INITIAL_TYPE_WIDTH,
  })

  const handleCellEditStart = useCallback(params => {
    if (params.field === "type") {
      setColumnWidths(prev => ({ ...prev, type: EDIT_VIEW_TYPE_WIDTH }))
    }
  }, [])

  const handleCellEditStop = useCallback(params => {
    if (params.field === "type") {
      setColumnWidths(prev => ({ ...prev, type: INITIAL_TYPE_WIDTH }))
    }
  }, [])

  return { columnWidths, handleCellEditStart, handleCellEditStop }
}
