import PropTypes from "prop-types"

class CDynamicElement {
  constructor({ itemId, type, description, unity, price, code }) {
    this.itemId = itemId || null
    this.type = type || 0
    this.description = description || ""
    this.unity = unity || "pa"
    this.price = price || 0
    this.code = code || ""
  }
}

export default CDynamicElement

const DynamicElementPropType = PropTypes.shape({
  itemId: PropTypes.string,
  type: PropTypes.number,
  description: PropTypes.string,
  unity: PropTypes.string,
  price: PropTypes.number,
  code: PropTypes.string,
})

export { DynamicElementPropType }