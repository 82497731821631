import React from "react"
import PropTypes from "prop-types"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import Modal from "@mui/material/Modal"
import { Card, CardContent } from "@mui/material"
import i18n from "i18n"
import { greyButtonStyle } from "common/buttons"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
}

const cardStyle = {
  width: 600,
  height: 200,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}

const contentStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  height: "100%",
}

const textStyle = {
  width: "75%",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  textAlign: "left",
  fontFamily: "Helvetica",
  fontSize: "16px",
  fontWeight: 300,
  lineHeight: "25px",
}

const buttonStyle = {
  width: "25%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}

const HowToAddDynamicElementsModal = ({ open, onClose, onUpdate }) => {
  return (
    <Modal
      open={open}
      // onClose={() => onClose(false)}
      aria-labelledby="confirm-delete-title"
      aria-describedby="confirm-delete-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Typography variant="h4" component="div" sx={{}}>
            {i18n.t("dynamicElement.modalHowToAdd.title")}
          </Typography>
          <Card sx={cardStyle}>
            <CardContent sx={contentStyle}>
              <Typography variant="h6" component="div" sx={textStyle}>
                {i18n.t("dynamicElement.modalHowToAdd.updateCard1")}
              </Typography>
              <Box sx={buttonStyle}>
                <Button
                  variant="outlined"
                  sx={greyButtonStyle}
                  onClick={() => onUpdate()}
                >
                  {i18n.t("buttons.update")}
                </Button>
              </Box>
            </CardContent>
          </Card>

          <Card sx={cardStyle}>
            <CardContent sx={contentStyle}>
              <Typography variant="h6" component="div" sx={textStyle}>
                {i18n.t("dynamicElement.modalHowToAdd.updateCard2")}
              </Typography>
              <Box sx={buttonStyle}>
                <Button
                  variant="outlined"
                  sx={greyButtonStyle}
                  onClick={() => onClose(false)}
                >
                  {i18n.t("buttons.start")}
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </Modal>
  )
}

HowToAddDynamicElementsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
}

export default HowToAddDynamicElementsModal
