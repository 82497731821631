import React, { useReducer } from "react"
import ProjectContext from "./projectContext"
import { API, Auth } from "aws-amplify"
import PropTypes from "prop-types"
import ProjectReducer from "./projectReducer"
import axios from "axios"
import * as amplitude from '@amplitude/analytics-browser';
import {
  CREATE_PROJECT_SUCCESS,
  ON_GET_PROJECTS,
  ON_GET_CHAPTERS,
  GET_PROJECTS_SUCCESS,
  GET_CHAPTERS_SUCCESS,
  GET_PROJECT_SUCCESS,
  UPDATE_PROJECT_SUCCESS,
  DELETE_CHAPTERS_SUCCESS,
  DELETE_PARTIDA_SUCCESS,
  ON_UPLOAD_PROJECT,
  UPLOAD_PROJECT_SUCCESS,
  UPLOAD_PROJECT_ERROR,
  CLEAR_UPLOAD_PROCESS,
  DELETE_PROJECT_SUCCESS,
  ADD_TO_FAVORITES_SUCCESS,
  GET_VERSIONS_SUCCESS,
  
} from "../types"

const ProjectState = props => {
  const initialState = {
    projects: [],
    templateProjects: [],
    chapters: [],
    projectInfo: null,
    createProcess: {
      creating_project: false,
      create_success: false,
      create_error: false,
    },
    uploadProcess: {
      uploading_project: false,
      upload_success: false,
      upload_error: false,
    },
    loadingProjects: false,
    loadingImportPlantilla: false,
    loadingChapters: false,
    capitulosPresupuesto: [],
    versions: [],
    selectedProject: null,
    fileUploaded : {}
  }
  const [state, dispatch] = useReducer(ProjectReducer, initialState)
  const APINAME = "dev-PLANHOPPER-API"
  const SERVICE_BUDGET = 'service-budget'
  const SERVICE_CORE_BASE = "service-core-base"
  const UploadProjects = async ({ files, infoProject }) => {
    try {
      dispatch({
        type: ON_UPLOAD_PROJECT,
      })
      const headers = {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        "Access-Control-Allow-Origin": "*",
      }
      for (var file of files) {
        const body = {
          filename: file.name,
          extension: file.name.split(".").slice(-1)[0],
          size: file.size,
          tags: infoProject.tags,
          customDB: false,
          importedProject: true,
          description: infoProject.description,
          members: [infoProject.client],
          orgId: "",
          projectId: infoProject.projectId,
          contentType: file.type,
          importedDatabase : false,
          importedTemplate : false
        }
        const res = await API.post(SERVICE_BUDGET, 'import', {
          headers: headers,
          body: body,
        })
        uploadFile({ file: file, presignedPostData: res.uploadInfo })
      }
    } catch (e) {
      console.log(e)
    }
  }
  const UploadProject = async ({ file, infoProject }) => {
    try {
      //make dispatch to wait modal
      dispatch({
        type: ON_UPLOAD_PROJECT,
      })

      const headers = {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        "Access-Control-Allow-Origin": "*",
      }
      const body = {
        filename: file.name,
        extension: file.name.split(".").slice(-1)[0],
        size: file.size,
        tags: infoProject.tags,
        customDB: false,
        importedProject: true,
        importedDatabase: false,
        importedTemplate: false,
        description: infoProject.description,
        members: [infoProject.client],
        orgId: "",
        projectId: infoProject.projectId ? infoProject.projectId :  file.projectId,
        contentType: file.type,
      }

      const res = await API.post(SERVICE_BUDGET, "/import", {
        headers: headers,
        body: body,
      })
      dispatch({
        type: "UPDATE_FILE_UPLOADED",
        payload: res.file.fileId
      })
      uploadFile({ file: file, presignedPostData: res.uploadInfo, projectId: infoProject.projectId })
    } catch (error) {
      console.log(error)
    }
  }
  const uploadProjectFromOtherFormats = async(files, organizationId) =>{
    try {
      dispatch({
        type: ON_UPLOAD_PROJECT,
      })
      const headers = {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        "Access-Control-Allow-Origin": "*",
      }
      const formData = new FormData();
      for (var file of files) {
        formData.append('files', file);
      }
      const res = await API.post(SERVICE_CORE_BASE, '/files/upload', {
        headers: headers,
        body: formData,
        queryStringParameters: {
          organization_id: organizationId,
          origin: "support",
        },
      })
    } catch (e) {
      console.log(e)
    }
  }
  const uploadFile = async ({ file, presignedPostData, projectId }) => {
    try {
      const formData = new FormData()
      Object.keys(presignedPostData.fields).forEach(key => {
        formData.append(key, presignedPostData.fields[key])
      })
      formData.append("file", file)
      if (projectId) {
        formData.append("projectId", projectId)
      }

      const res = await axios.post(presignedPostData.url, formData)
      if (res.status === 204) {
        dispatch({
          type: UPLOAD_PROJECT_SUCCESS
        })
      }
    } catch (error) {
      dispatch({
        type: UPLOAD_PROJECT_ERROR,
      })
    }
  }
  const clearUploadProcess = () => {
    dispatch({
      type: CLEAR_UPLOAD_PROCESS,
    })
  }
  const createProject = async data => {
    try {
      const apiName = "dev-PLANHOPPER-API"
      const path = "/api/project/create"
      dispatch({
        type: 'ON_CREATE_PROJECT'
      })
      const myInit = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
          "Access-Control-Allow-Origin": "*",
        },
        body: {
          name: data.name,
          description: data.description,
          identifierNumber: data.identifier_number,
          projectImage: data.imagen,
          orgAddress: data.orgAddress,
          client: data.client? data.client==' ' ? '' : data.client : null,
          tags: data.tags,
          is_template: data.is_template ? data.is_template : false,
        },
      }
      const res = await API.post(apiName, path, myInit)
      amplitude.track('Project Created')
      dispatch({
        type: CREATE_PROJECT_SUCCESS,
        payload: {
          project: res
        },
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  const duplicateProject = async (projectId, is_template) => {
    try {
      const path = "/projects/copy"
      dispatch({
        type: 'ON_CREATE_PROJECT'
      })
      const myInit = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
          "Access-Control-Allow-Origin": "*",
        },
        body:{
          projectId: projectId,
          is_template: is_template
        }
      }
      const res = await API.post(SERVICE_CORE_BASE, path, myInit)
      return res
    } catch (error) {
      console.log(error)
    }
  }


  const createProjectFromNoProjects = async data => {
    try {
      const apiName = "dev-PLANHOPPER-API"
      const path = "/api/project/create"
      dispatch({
        type: 'ON_CREATE_PROJECT'
      })
      const myInit = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
          "Access-Control-Allow-Origin": "*",
        },
        body: {
          name: data.name,
          description: data.description,
          identifierNumber: data.identifier_number,
          projectImage: data.imagen,
          orgAddress: data.orgAddress,
          client: data.client? data.client==' ' ? '' : data.client : null,
          tags: data.tags,
        },
      }
      const res = await API.post(apiName, path, myInit)
      dispatch({
        type: 'CREATE_PROJECT_SUCCESS_2',
        payload: {
          project: res
        },
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }
  const deleteProject = async Id => {
    try {
      const apiName = "dev-PLANHOPPER-API"
      const path = "/api/project"
      const myInit = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
          "Access-Control-Allow-Origin": "*",
        },
        body: {
          projectId: Id,
        },
      }
      const res = await API.del(apiName, path, myInit)
      dispatch({
        type: DELETE_PROJECT_SUCCESS,
        payload: res,
      })
    } catch (error) {
      console.log(error)
    }
  }
  const updateProject = async data => {
    try {
      const apiName = "dev-PLANHOPPER-API"
      const path = "/api/project"
      const myInit = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
          "Access-Control-Allow-Origin": "*",
        },
        body: {
          projectId: data.projectId,
          name: data.name,
          description: data.description,
          identifierNumber: data.identifier_number,
          projectImage: data.imagen,
          orgAddress: data.orgAddress,
          client: data.client? data.client==' ' ? '' : data.client : null,
          tags: data.tags,
          discount: data.descuento ? parseInt(data.descuento) : 0,
          igic: data.igic ? parseInt(data.igic) : 0,
          costeIndirecto: data.costeIndirecto,
          costeGeneral: data.costeGeneral,
          industrialProfit: data.industrialProfit,
          costeTotal: data.costeTotal,
          iva: data.iva ? parseInt(data.iva) : 0,
          conditions:data.conditions ? data.conditions : '',
          cover:data.cover ? data.cover : '',
          discount_notes:data.discount_notes ? data.discount_notes : '',
        },
      }
      const res = await API.put(apiName, path, myInit)
      if(state.projects.length==0){
        await getProjects()
      }
      dispatch({
        type: UPDATE_PROJECT_SUCCESS,
        payload: {
          projectId: data.projectId,
          name: data.name,
          description: data.description,
          identifierNumber: data.identifier_number,
          projectImage: data.imagen,
          orgAddress: data.orgAddress,
          discount: data.descuento ? parseInt(data.descuento) : 0,
          igic: data.igic ? parseInt(data.igic) : 0,
          client: data.client,
          tags: data.tags,
          costeIndirecto: data.costeIndirecto,
          costeGeneral: data.costeGeneral,
          industrialProfit: data.industrialProfit,
          costeTotal: data.costeTotal,
          iva: data.iva ? parseInt(data.iva) : 0,
        },
      })
    } catch (error) {
      console.log(error)
    }
  }
  const updatePresupuesto = async (data, pres) => {
    try {
      const apiName = "dev-PLANHOPPER-API"
      const path = "/api/project"
      const myInit = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
          "Access-Control-Allow-Origin": "*",
        },
        body: {
          projectId: data.projectId,
          name: data.name,
          description: data.description,
          identifierNumber: data.identifierNumber,
          projectImage: data.projectImage,
          orgAddress: data.orgAddress,
          client: data.client? data.client==' ' ? '' : data.client : null,
          tags: data.tags,
          costeIndirecto: parseInt(pres.gi),
          costeGeneral: parseInt(pres.gg),
          industrialProfit: parseInt(pres.bi),
          costeTotal: 0,
        },
      }
      const res = await API.put(apiName, path, myInit)
      dispatch({
        type: UPDATE_PROJECT_SUCCESS,
        payload: {
          projectId: data.projectId,
          name: data.name,
          description: data.description,
          identifier_number: data.identifierNumber,
          orgAddress: data.orgAddress,
          client: data.client,
          tags: data.tags,
          costeIndirecto: parseInt(pres.gi),
          costeGeneral: parseInt(pres.gg),
          industrialProfit: parseInt(pres.bi),
          costeTotal: 0,
        },
      })
    } catch (error) {
      console.log(error)
    }
  }

  const updateProjectMargins = async (data, pres) => {
    try {
      const apiName = 'service-budgeting-temp'
      const path = "/updateProjectMargins"
      const myInit = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
          "Access-Control-Allow-Origin": "*",
        },
        body: {
          projectId: data.projectId,
          margins: { 
            costeIndirecto: parseFloat(pres.gi),
            costeGeneral: parseFloat(pres.gg),
            industrialProfit: parseFloat(pres.bi) },
          editPartMargins: false,
        },
      }
      const res = await API.put(apiName, path, myInit)
    } catch (error) {
      console.log(error)
    }
  }
  const getProject = async projectId => {
    try {
  
      const apiName = "dev-PLANHOPPER-API"
      const path = "/api/project/" + projectId
      const myInit = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
          "Access-Control-Allow-Origin": "*",
        },
      }

      const res = await API.get(apiName, path, myInit)
      dispatch({
        type: GET_PROJECT_SUCCESS,
        payload: res,
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getProjects = async () => {
    try {
      dispatch({
        type: ON_GET_PROJECTS,
      })
      const headers = {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        "Access-Control-Allow-Origin": "*",
	"Accept": "application/json",
      }
      const apiName = "dev-PLANHOPPER-API"
      const path = "/api/project"
      const res = await API.get(apiName, path, {
        headers: headers,
      })
      let fav = []
      let noFav = []
      for (let i = 0; i < res.length; i++) {
       //console.log(res[i], 'eeee')
       if(!res[i].deletedAt){
        if(res[i].favourite){
          fav.push(res[i])
        }else{
          noFav.push(res[i])
        }
      }}
      let proyectosFinales = []
      fav.push(...noFav)


      dispatch({
        type: GET_PROJECTS_SUCCESS,
        payload: fav,
      })
    } catch (error) {
      console.log(error)
    }
  }


  const sortProjectsBy = (param, encontrarCliente) => {
    console.log(param, 'eeee')
    let projects = state.projects
    switch (param) {
      case 'Nombre':
        projects.sort((a, b) => {
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1
          }
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1
          }
          return 0
        })
        console.log(projects, 'eeee')
        break
      case 'Fecha':
        projects.sort((a, b) => {
          if (a.createdAt > b.createdAt) {
            return 1
          }
          if (a.createdAt < b.createdAt) {
            return -1
          }
          return 0
        })
        break
      case 'Cliente':
        projects.sort((a, b) => {
          let clientA = encontrarCliente(a.client)
          let clientB = encontrarCliente(b.client)
          console.log(clientA, clientB, 'eeee')
          if (clientB == undefined){
            return -1
          }
          if (clientA > clientB) {
            return 1
          }
          if (clientA < clientB) {
            return -1
          }
          return 0
        })
        break
      case 'Dirección':
        projects.sort((a, b) => {
          if (a.orgAddress.toLowerCase() > b.orgAddress.toLowerCase()) {
            return 1
          }
          if (a.orgAddress.toLowerCase() < b.orgAddress.toLowerCase()) {
            return -1
          }
          return 0
        })
        break
      default:
        break
    }
    dispatch({
      type: GET_PROJECTS_SUCCESS,
      payload: projects,
    })
  }


  const deleteChapter = async (chapter, project) => {
    try {
      const apiName = "dev-PLANHOPPER-API"
      const path = "/api/budget/delete/chapter"
      const payload = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
          "Access-Control-Allow-Origin": "*",
        },
        body: {
          chapterId: chapter,
          projecId: project,
        },
      }
      const res = await API.del(apiName, path, payload)
      dispatch({
        type: DELETE_CHAPTERS_SUCCESS,
        payload: res,
      })
    } catch (error) {
      console.log(error)
    }
  }
  const deletePartida = async partida => {
    try {
      const apiName = "dev-PLANHOPPER-API"
      const path = "/api/budget/delete/partida"
      const payload = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
          "Access-Control-Allow-Origin": "*",
        },
        body: {
          partidaId: partida,
        },
      }
      const res = await API.del(apiName, path, payload)
      dispatch({
        type: DELETE_PARTIDA_SUCCESS,
        payload: res,
      })
    } catch (error) {
      console.log(error)
    }
  }
  const createChapter = async data => {
    try {
      const apiName = "dev-PLANHOPPER-API"
      const path = "/api/budget/create/chapter"
      const payload = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
          "Access-Control-Allow-Origin": "*",
        },
        body: {
          name: "",
          description: "",
          parentId: data.parentId,
          projectId: data.projectId,
          price: data.price,
          tags: [],
        },
      }
      const res = await API.post(apiName, path, payload)
      dispatch({
        type: DELETE_CHAPTERS_SUCCESS,
        payload: res,
      })
    } catch (error) {
      console.log(error)
    }
  }
  const getChapters = async projectId => {
    try {
      dispatch({
        type: ON_GET_CHAPTERS,
      })
      const headers = {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        "Access-Control-Allow-Origin": "*",
      }
      const apiName = "dev-PLANHOPPER-API"
      const path = "/api/budget/v2/" + projectId
      const res = await API.get(apiName, path, {
        headers: headers,
      })

      dispatch({
        type: GET_CHAPTERS_SUCCESS,
        payload: res,
      })
    } catch (error) {
      console.log(error)
    }
  }
  const createEmptChapter = async projectId => {
    try {
      const apiName = "dev-PLANHOPPER-API"
      let headers = {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        "Access-Control-Allow-Origin": "*",
      }
      const capitulo = await API.post(apiName, "/api/budget/create/chapter", {
        headers: headers,
        body: {
          name: null,
          description: null,
          parentId: null,
          projectId: projectId,
          price: 0,
          tags: [],
        },
      })

      getChapters(projectId)
    } catch (error) {
      console.log(error)
    }
  }

  const addToFavorites = async(projectId) =>{
    try {
      const apiName = "service-projects"
      const path = "/addToFavourite/"+projectId
      const payload = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
          "Access-Control-Allow-Origin": "*",
        },
      }
      const res = await API.put(apiName, path, payload)
      dispatch({
        type: ADD_TO_FAVORITES_SUCCESS,
        payload: res,
      })
    }catch (error) {
      console.log(error)
    }
  }

  const removeFromFavorites = async(projectId) =>{
    try {
      const apiName = "service-projects"
      const path = "/removeFromFavourite/"+projectId
      const payload = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
          "Access-Control-Allow-Origin": "*",
        },
      }
      const res = await API.put(apiName, path, payload)
      dispatch({
        type: ADD_TO_FAVORITES_SUCCESS,
        payload: res,
      })
    }catch (error) {
      console.log(error)
    }
  }

  const getProjectVersions = async(projectId, accountId) =>{
    try {
      const apiName = "service-budget"
      const path = "/getProjectVersions/?projectId="+projectId+"&accountId="+accountId
      const payload = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
          "Access-Control-Allow-Origin": "*",
        },
      }
      const res = await API.get(apiName, path, payload)
     
      dispatch({
        type: GET_VERSIONS_SUCCESS,
        payload: res,
      })
    }catch (error) {
      console.log(error)
    }
  }

  const createProjectWithTemplate = async data => {
    try {
      const apiName = "dev-PLANHOPPER-API"
      const path = "/api/project/create"
      dispatch({
        type: 'ON_CREATE_PROJECT'
      })
      const myInit = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
          "Access-Control-Allow-Origin": "*",
        },
        body: {
          name: data.name,
          description: data.description,
          identifierNumber: data.identifierNumber,
          projectImage: data.projectImage,
          orgAddress: data.orgAddress,
          client: data.client? data.client==' ' ? '' : data.client : null,
          tags: data.tags,
        },
      }
      const res = await API.post(apiName, path, myInit)
      dispatch({
        type: CREATE_PROJECT_SUCCESS,
        payload: {
          project: res
        },
      })
      await importPlantilla2Project(res.projectId, data.templateId)
      return res
    } catch (error) {
      console.log(error)
    }
  }

  const importPlantilla2Project = async(projectId, templateId) =>{
    try {
      dispatch({
        type: 'ON_IMPORT_PLANTILLA',
      })
      const APINAME = "service-budget"
      let headers = {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        "Access-Control-Allow-Origin": "*",
      }
      let body = {
        projectId: projectId,
        templateId: templateId
      }
      const res = await API.post(APINAME, "/import/template", {
        headers: headers,
        body: body
      })
      dispatch({
        type: 'IMPORT_PLANTILLA_SUCCESS',
      })

    } catch (error) {
      console.log(error)
    }
  }

  const updateCoverAndConditions = async(projectInfo, cover, conditions, cover_fields) =>{
    try {
      const apiName = "dev-PLANHOPPER-API"
      const path = "/api/project"
      const myInit = {

        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
          "Access-Control-Allow-Origin": "*",
        },

        body: {
          projectId: projectInfo.projectId,
          name: projectInfo.name,
          description: projectInfo.description,
          identifierNumber: projectInfo.identifierNumber,
          projectImage: projectInfo.projectImage,
          orgAddress: projectInfo.orgAddress,
          client: projectInfo.client,
          tags: projectInfo.tags,
          discount: projectInfo.discout ? parseInt(projectInfo.discout) : 0,
          costeIndirecto: projectInfo.costeIndirecto,
          costeGeneral: projectInfo.costeGeneral,
          industrialProfit: null,
          cover_fields: cover_fields,
          iva: projectInfo.iva ? parseInt(projectInfo.iva) : 0,
          cover: cover,
          conditions: conditions,
          costeTotal: null
        },
      }
      const res = await API.put(apiName, path, myInit)
      if(state.projects.length==0){
        await getProjects()
      }
      dispatch({
        type: UPDATE_PROJECT_SUCCESS,
        payload: {
          projectId: projectInfo.projectId,
          name: projectInfo.name,
          description: projectInfo.description,
          identifierNumber: projectInfo.identifier_number,
          projectImage: projectInfo.imagen,
          orgAddprojectInfos: projectInfo.orgAddprojectInfos,
          discount: projectInfo.descuento ? parseInt(projectInfo.descuento) : 0,
          client: projectInfo.client,
          tags: projectInfo.tags,
          costeIndirecto: projectInfo.costeIndirecto,
          costeGeneral: projectInfo.costeGeneral,
          industrialProfit: projectInfo.industrialProfit,
          costeTotal: projectInfo.costeTotal ? projectInfo.costeTotal : 0,
          iva: projectInfo.iva ? parseInt(projectInfo.iva) : 0,
          cover: cover,
          conditions: conditions
        },
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <ProjectContext.Provider
      value={{
        test: state,
        projects: state.projects,
        templateProjects: state.templateProjects,
        chapters: state.chapters,
        projectInfo: state.projectInfo,
        createProcess: state.createProcess,
        uploadProcess: state.uploadProcess,
        loadingProjects: state.loadingProjects,
        loadingChapters: state.loadingChapters,
        capitulosPresupuesto: state.capitulosPresupuesto,
        selectedProject: state.selectedProject,
        updateCoverAndConditions,
        UploadProject,
        getProjects,
        getProject,
        createProject,
        getChapters,
        createChapter,
        updateProject,
        updatePresupuesto,
        deleteChapter,
        deletePartida,
        createEmptChapter,
        deleteProject,
        clearUploadProcess,
        UploadProjects,
        updateProjectMargins,
        addToFavorites, removeFromFavorites, getProjectVersions,
        importPlantilla2Project,
        loadingImportPlantilla: state.loadingImportPlantilla,
        versions: state.versions,
        createProjectWithTemplate,
        createProjectFromNoProjects,
        duplicateProject,
        fileUploaded: state.fileUploaded,
        sortProjectsBy,
        uploadProjectFromOtherFormats,
      }}
    >
      {props.children}
    </ProjectContext.Provider>
  )
}

ProjectState.propTypes = {
  children: PropTypes.element.isRequired,
}

export default ProjectState

