import { useReducer } from "react"
import { API, Auth } from "aws-amplify"
import React from "react"
import PropTypes from "prop-types"
import axios from "axios"
import BankPricesContext from "./bankPricesContext"
import BankPricesReducer from "./bankPricesReducer"
import * as amplitude from "@amplitude/analytics-browser"

import { LOADING_BANKPRICES, GET_BANK_PRICES, LOADING_SUBCAPS } from "../types"
import {
  ON_UPLOAD_FILE,
  CLEAN_IMPORT_PROGRESS,
  UPLOAD_FILE_ERROR,
  UPLOAD_FILE_SUCCESS,
  ADD_PARTIDA_TO_MOVE,
  ADD_CAPITULO_MOVE,
  DEL_CAPITULO,
} from "./types"

import {
  getChapterParentId,
  getChapterById,
  getPartIndex,
  getChapterIndex,
  getPreviousPartByParentAndPosition,
  getNextPartByParentAndPosition,
  getChapterByParentAndPosition,
} from "utils/budgetUtils"

class Capitulo {
  constructor(data) {
    this.capituloInfo = data.chapterInfo
    this.parentId = data.chapterInfo.parentId ? data.chapterInfo.parentId : null
    this.partidas = data.parts
      ? data.parts.map(partida => new Partida(partida))
      : []
    this.chapterId = data.chapterInfo.chapterBankId
    this.subcapitulos = data.subcapitulos
      ? data.subcapitulos.map(subcapitulo => new Capitulo(subcapitulo))
      : []
    this.isExpanded = false
    this.total = data.chapterInfo.price ? data.chapterInfo.price : 0
    this.checked = false
    this.status = "OPEN"
    this.empty = false
    this.warning = false
    this.costeFinal = data.chapterInfo.costeFinal
      ? data.chapterInfo.costeFinal
      : 0
    ;(this.updates = null),
      (this.finalClientPrice = data.chapterInfo.finalClientPrice
        ? data.chapterInfo.finalClientPrice
        : 0)
    this.sended = data.chapterInfo.sended ? data.chapterInfo.sended : false
    this.selfManagement = data.chapterInfo.selfManagement
      ? data.chapterInfo.selfManagement
      : false
  }
  update(data) {
    this.capituloInfo = data
  }
  addCapitulo(capitulo) {
    this.subcapitulos.push(capitulo)
  }
  updateTotal = () => {
    if (this.partidas.length > 0) {
      this.total = this.partidas.reduce(
        (total, partida) => total + partida.userPrice * partida.quantity,
        0
      )
    } else {
      this.total = 0
      for (let capitulo of this.subcapitulos) {
        capitulo.updateTotal()
        this.total = this.total + capitulo.total
      }
    }
  }
  updateTotal2 = () => {
    if (this.partidas.length > 0) {
      this.costeFinal = this.partidas.reduce(
        (total, partida) =>
          total +
          (partida.costeFinal - (partida.discount / 100) * partida.costeFinal) *
            partida.quantity,
        0
      )
    } else {
      this.costeFinal = 0
      for (let capitulo of this.subcapitulos) {
        capitulo.updateTotal2()
        this.costeFinal = this.costeFinal + capitulo.costeFinal
      }
    }
  }
  checkEmpty = () => {
    if (this.partidas.length == 0 && this.subcapitulos.length == 0) {
      this.empty = true
      this.warning = true
      return true
    } else if (this.partidas.length > 0) {
      for (let partida of this.partidas) {
        if (partida.empty) {
          this.warning = true
          return true
        }
      }
      this.warning = false
      return false
    } else if (this.subcapitulos.length > 0) {
      let hasWarnings = false
      for (let capitulo of this.subcapitulos) {
        if (capitulo.checkEmpty() === true) {
          hasWarnings = true
        }
      }
      this.warning = hasWarnings
      return hasWarnings
    }
    return false
  }

  checkUpdates = () => {
    if (this.partidas.length > 0) {
      for (let partida of this.partidas) {
        if (partida.updates) {
          this.updates = partida.updates
          return true
        }
      }
    } else if (this.subcapitulos.length > 0) {
      for (let capitulo of this.subcapitulos) {
        capitulo.checkUpdates()
        if (capitulo.updates != null) {
          this.updates = capitulo.updates
        }
      }
    }
  };

  *preOrder() {
    yield this
    for (let capitulo of this.subcapitulos) {
      yield* capitulo.preOrder()
    }
  }

  remove(capituloId) {
    for (let capitulo of this.preOrder()) {
      const filtered = capitulo.subcapitulos.filter(
        element => element.chapterId !== capituloId
      )
      if (capitulo.subcapitulos.length !== filtered.length) {
        capitulo.subcapitulos = filtered
        break
      }
    }
  }

  removePartida(capituloId, partidaId) {
    for (let capitulo of this.preOrder()) {
      if (capitulo.chapterId === capituloId) {
        const filtered = capitulo.partidas.filter(
          element => element.partidaId !== partidaId
        )
        if (capitulo.partidas.length !== filtered.length) {
          capitulo.partidas = filtered
          break
        }
      }
    }
  }
  addPartida(capituloId, partida, indexPartida) {
    for (let capitulo of this.preOrder()) {
      if (capitulo.chapterId === capituloId) {
        capitulo.partidas.find(
          element => element.partidaId === partida.partidaId
        )
          ? null
          : //capitulo.partidas.splice(indexPartida+1, 0, partida)
            capitulo.partidas.push(partida)
        break
      }
    }
  }
  addPartidaToMove(capituloId, partida, indexPartida) {
    partida.position = indexPartida
    for (let capitulo of this.preOrder()) {
      if (capitulo.chapterId === capituloId) {
        capitulo.partidas.find(
          element => element.partidaId === partida.partidaId
        )
          ? null
          : capitulo.partidas.splice(indexPartida, 0, partida)
        break
      }
    }
  }
  addCapitulo(parentId, capituloNue, indexCapitulo) {
    for (let capitulo of this.preOrder()) {
      if (parentId == "root") {
        capituloNue.chapterInfo.parentId = "root"
        let aux = [...capitulo.subcapitulos]
        let aux2 = {
          chapterInfo: capituloNue.chapterInfo,
          subcapitulos: [],
          parts: capituloNue.parts,
        }
        let capi = new Capitulo(aux2)
        capitulo.subcapitulos.find(
          element =>
            element?.capituloInfo?.chapterBankId ===
            capituloNue?.chapterInfo?.chapterBankId
        )
          ? null
          : aux.splice(indexCapitulo, 0, capi)
        capitulo.subcapitulos = aux

        break
      }
      if (capitulo.chapterId === parentId) {
        let aux = [...capitulo.subcapitulos]
        let aux2 = {
          chapterInfo: capituloNue.chapterInfo,
          subcapitulos: [],
          parts: capituloNue.parts,
        }
        let capi = new Capitulo(aux2)
        capitulo.subcapitulos.find(
          element =>
            element?.capituloInfo?.chapterBankId ===
            capituloNue?.chapterInfo?.chapterBankId
        )
          ? null
          : aux.splice(indexCapitulo, 0, capi)
        capitulo.subcapitulos = aux

        break
      }
    }
  }

  addCapituloMove(parentId, capituloNue, indexCapitulo) {
    for (let capitulo of this.preOrder()) {
      if (parentId == "root") {
        capituloNue.capituloInfo.parentId = "root"
        let aux = [...capitulo.subcapitulos]
        let aux2 = {
          chapterInfo: { ...capituloNue.capituloInfo, position: indexCapitulo },
          subcapitulos: [],
          parts: capituloNue.partidas,
        }
        let capi = new Capitulo(aux2)
        capitulo.subcapitulos.find(
          element =>
            element?.capituloInfo?.chapterBankId ===
            capituloNue?.capituloInfo?.chapterBankId
        )
          ? null
          : aux.splice(indexCapitulo, 0, capi)

        capitulo.subcapitulos = aux.sort(
          (a, b) => a.capituloInfo.position - b.capituloInfo.position
        )
        break
      }
      if (capitulo.chapterId === parentId) {
        let aux = [...capitulo.subcapitulos]
        let aux2 = {
          chapterInfo: { ...capituloNue.capituloInfo, position: indexCapitulo },
          subcapitulos: [],
          parts: capituloNue.partidas,
        }
        let capi = new Capitulo(aux2)
        capitulo.subcapitulos.find(
          element =>
            element?.capituloInfo?.chapterBankId ===
            capituloNue?.capituloInfo?.chapterBankId
        )
          ? null
          : aux.splice(indexCapitulo, 0, capi)

        capitulo.subcapitulos = aux.sort(
          (a, b) => a.capituloInfo.position - b.capituloInfo.position
        )
        break
      }
    }
  }

  addMultipleParts(capituloId, partidas) {
    for (let capitulo of this.preOrder()) {
      if (capitulo.chapterId === capituloId) {
        if (capitulo.partidas.length == 0) {
          capitulo.partidas = [...capitulo.partidas, ...partidas]
        }
        break
      }
    }
  }

  addMultipleChapters(capituloId, capitulos) {
    for (let capitulo of this.preOrder()) {
      if (capitulo.chapterId === capituloId) {
        if (capitulo.subcapitulos.length == 0) {
          capitulo.subcapitulos = [...capitulo.subcapitulos, ...capitulos]
        }
        break
      }
    }
  }

  updateIsExpanded(capituloId) {
    for (let capitulo of this.preOrder()) {
      if (capitulo.chapterId === capituloId) {
        capitulo.isExpanded = !capitulo.isExpanded
        break
      }
    }
  }
  getInfoSelected() {
    let selectedInfo = []
    for (let capitulo of this.preOrder()) {
      if (capitulo.checked) {
        selectedInfo.push(capitulo.capituloInfo)
      } else {
        for (let partida of capitulo.partidas) {
          if (partida.checked) {
            selectedInfo.push(partida.partidaInfo)
          }
        }
      }
    }
    return selectedInfo
  }
  checkPartida(capituloId, partidaId) {
    for (let capitulo of this.preOrder()) {
      if (capitulo.chapterId === capituloId) {
        for (let partida of capitulo.partidas) {
          if (partida.partidaId === partidaId) {
            partida.checked = !partida.checked
            break
          }
        }
      }
    }
  }
  checkCapitulo(capituloId, value) {
    for (let capitulo of this.preOrder()) {
      if (
        capitulo.chapterId === capituloId ||
        capitulo.capituloInfo.chapterId === capituloId
      ) {
        capitulo.checked = value
        if (capitulo.subcapitulos.length > 0) {
          for (let subcapitulo of capitulo.subcapitulos) {
            subcapitulo.checkCapitulo(subcapitulo.chapterId, value)
          }
        }
        if (capitulo.partidas.length > 0) {
          for (let partida of capitulo.partidas) {
            partida.checked = value
          }
        }
        break
      }
    }
  }

  updatePartida(newData, capituloId, final) {
    for (let capitulo of this.preOrder()) {
      if (capitulo.chapterId === capituloId) {
        for (let partida of capitulo.partidas) {
          if (partida.partidaId === newData.partidaId) {
            partida.updatePartida(newData)
            break
          }
        }
        break
      }
    }
    if (final) {
      this.updateTotal2()
    } else {
      this.updateTotal()
    }
  }
  updateCapitulo(newData, capituloId) {
    for (let capitulo of this.preOrder()) {
      if (capitulo.chapterId === capituloId) {
        capitulo.update(newData)
        break
      }
    }
  }

  isEmpty() {
    for (let capitulo of this.preOrder()) {
      if (capitulo.subcapitulos.length == 0) {
        if (capitulo.partidas.length == 0) {
          //capitulo.update(capitulo)
        }
      }
    }
  }
  getChaptersToGroup() {
    let chaptersToGroup = []
    for (let capitulo of this.subcapitulos) {
      if (capitulo.checked === true) {
        chaptersToGroup.push(capitulo.capituloInfo)
      } else {
        chaptersToGroup = chaptersToGroup.concat(capitulo.getChaptersToGroup())
      }
    }
    return chaptersToGroup
  }

  postGroupParts(newChapter, newParts, oldParts) {
    try {
      for (let part of oldParts) {
        this.removePartida(part.chapterId, part.partidaId)
      }
      let chapterParentId = newChapter.parentId
      const chapter = new Capitulo({
        capituloInfo: newChapter,
        partidas: newParts.map(part => ({
          partidaId: part,
        })),
      })
      if (chapterParentId === "root") {
        this.subcapitulos.push(chapter)
      } else {
        for (let capitulo of this.preOrder()) {
          if (capitulo.chapterId === chapterParentId) {
            capitulo.subcapitulos.push(chapter)
            break
          }
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  postGroupChapters(chapterCreated, newChapters, oldChapters) {
    try {
      for (let chapter of oldChapters) {
        this.remove(chapter.chapterId)
      }
      let chapterParentId = chapterCreated.parentId
      let auxChapters = oldChapters
      for (let index = 0; index < auxChapters.length; index++) {
        auxChapters[index].parentId = chapterCreated.chapterId
      }
      const chapter = new Capitulo({
        capituloInfo: chapterCreated,
        subcapitulos: auxChapters,
      })
      if (chapterParentId === "root") {
        this.subcapitulos.push(chapter)
      } else {
        for (let capitulo of this.preOrder()) {
          if (capitulo.chapterId === chapterParentId) {
            capitulo.subcapitulos.push(chapter)
            break
          }
        }
      }
    } catch (error) {
      console.error(error)
    }
  }
}
class Partida {
  constructor(data) {
    this.partidaId = data.partBankId
    this.accountId = data.accountId
    this.userPrice = data.userPrice
      ? data.userPrice % 1 === 0
        ? data.userPrice
        : parseFloat(data.userPrice).toFixed(2)
      : 0
    this.price = data.price
      ? data.price % 1 === 0
        ? data.price
        : parseFloat(data.price).toFixed(2)
      : 0
    this.decomposition = data.decomposition ? data.decomposition : []
    this.measures = []
    this.quantity = data.quantity
    this.updatedAt = data.updatedAt
    this.createdAt = data.createdAt
    this.code = data.code
    this.description = data.description
    this.chapterId = data.chapterBankId
    this.name = data.name
    this.elementInfo = data.elementInfo
    this.parentId = data.chapterBankId
    ;(this.checked = false), (this.unity = data.unity ? data.unity : "")
    this.status = "OPEN"
    this.costeFinal = data.costeFinal ? data.costeFinal : 0
    this.empty =
      this.code === "" || this.description === "" || this.code === undefined
    this.industrialProfit = data.industrialProfit ? data.industrialProfit : null
    this.costeGeneral = data.costeGeneral ? data.costeGeneral : null
    this.costeIndirecto = data.costeIndirecto ? data.costeIndirecto : null
    this.updates = data.updates ? data.updates : null
    this.creadaEnFinal = data.creadaEnFinal ? data.creadaEnFinal : false
    ;(this.images = data.images ? data.images : null),
      (this.discount = data.discount ? data.discount : 0)
    this.finalClientPrice = data.finalClientPrice ? data.finalClientPrice : 0
    this.position = data.position ? data.position : 0
  }

  updatePartida(data) {
    this.userPrice = data.userPrice
    this.price = data.price
    this.decomposition = data.decomposition ? data.decomposition : []
    this.updatedAt = data.updatedAt
    this.createdAt = data.createdAt
    this.description = data.description
    this.name = data.name
    this.unity = data.unity ? data.unity : ""
  }
}

const BankPricesState = props => {
  const ENDPOINT = "Planhopper-Bank-Prices"
  const SERVICE_BUDGET_TEMP = "service-budgeting-temp"
  const initialState = {
    loading: false,
    bankpriceInfo: {},
    myPricesBank: new Capitulo({
      chapterInfo: {
        chapterId: "root",
      },
      partidas: null,
      subcapitulos: null,
    }),
    loadingCaps: false,
    selectedCategories: [],
    categoriesChecked: [],
    partsChecked: [],
    selectedParts: [],
    errorAgrupar: false,
    selectedPartGroup: [],
    selectedCategories2: [],
    myNonSpecifiedPricesBank: new Capitulo({
      chapterInfo: {
        chapterId: "root",
      },
      partidas: null,
      subcapitulos: null,
    }),
    importProgress: {
      uploading: false,
      uploadSuccess: null,
      uploadError: null,
    },
    loadingOrder: false,
    fileUploaded: {},
  }
  const [state, dispatch] = useReducer(BankPricesReducer, initialState)
  const commonHeaders = async () => {
    const headers = {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
      "Access-Control-Allow-Origin": "*",
    }
    return headers
  }
  const getMyBankPrices = async accountId => {
    try {
      dispatch({
        type: LOADING_BANKPRICES,
        payload: {
          myPricesBank: new Capitulo({
            chapterInfo: {
              chapterId: "root",
            },
            partidas: null,
            subcapitulos: null,
          }),
        },
      })
      const headers = await commonHeaders()
      const res = await API.get(ENDPOINT, `/getChaptersBankTree/` + accountId, {
        headers: headers,
      })
      let subcapitulosAux = []
      for (let capitulo of res.bankPrices) {
        let aux = new Capitulo(capitulo)
        subcapitulosAux.push(aux)
      }
      dispatch({
        type: GET_BANK_PRICES,
        payload: subcapitulosAux,
      })
    } catch (err) {
      console.log(err)
    }
  }

  const getSearchChapters = async res => {
    try {
      let subcapitulosAux = []
      for (let capitulo of res) {
        let aux = new Capitulo(capitulo)
        subcapitulosAux.push(aux)
      }

      dispatch({
        type: "GET_BANK_PRICES_SEARCH",
        payload: subcapitulosAux,
      })
      return subcapitulosAux
    } catch (err) {
      console.log(err)
    }
  }

  const getSubCaps = async (chapterId, accountId) => {
    const nuevasPartidas = []
    try {
      dispatch({
        type: LOADING_SUBCAPS,
      })
      const headers = await commonHeaders()
      const res = await API.get(
        ENDPOINT,
        `/getSubChaptersBankTree/?parentId=` +
          chapterId +
          "&accountId=" +
          accountId,
        { headers: headers }
      )
      if (res.bankPrices) {
        let parentId = ""
        let capsAux = []
        for (let i = 0; i < res.bankPrices.length; i++) {
          let aux = res.bankPrices[i]
          parentId = aux.parentId
          let aux2 = {
            chapterInfo: aux.chapterInfo,
            subcapitulos: [],
            parts: [],
          }
          capsAux.push(new Capitulo(aux2))
        }
        dispatch({
          type: "ADD_MULTIPLE_CHAPTERS",
          payload: {
            chapterId: parentId,
            capitulos: capsAux,
          },
        })
      } else {
        let chapterId = ""
        for (let i = 0; i < res.myParts.length; i++) {
          let aux = {
            ...res.myParts[i],
            elementInfo: null,
            chapterId: res.myParts[i].chapterBankId,
          }
          let partidaNueva = new Partida(aux)
          nuevasPartidas.push(partidaNueva)
          chapterId = res.myParts[i].chapterBankId
        }
        dispatch({
          type: "ADD_MULTIPLE_PARTS",
          payload: {
            chapterId: chapterId,
            partidas: nuevasPartidas,
          },
        })
      }
      return nuevasPartidas
    } catch (err) {
      console.log(err)
    }
  }

  const createInitialStructure = async accountId => {
    try {
      const headers = await commonHeaders()
      const res = await API.post(ENDPOINT, `/createInitialStructure`, {
        headers: headers,
        body: { accountId: accountId },
      })
    } catch (err) {
      console.log(err)
    }
  }

  const addBankChapter = async (
    parentId,
    projectId,
    indexCapitulo,
    accountId,
    root
  ) => {
    try {
      let payload = {
        headers: await commonHeaders(),
        body: {
          name: "",
          description: "",
          parentId: parentId,
          projectId: projectId,
          price: 0,
          tags: [],
          accountId: accountId,
          code: null,
          root: root,
          position: indexCapitulo,
        },
      }
      const res = await API.post(ENDPOINT, `/createChapterBank`, payload)
      let aux = { ...res, chapterInfo: res }
      dispatch({
        type: "ADD_CAPITULO",
        payload: {
          parentId: parentId,
          res: aux,
          indexCapitulo: indexCapitulo,
        },
      })
    } catch (error) {
      console.log(error)
    }
  }

  const updateChapterBank = async data => {
    try {
      let headers = await commonHeaders()
      let body = {
        name: data.name ? data.name : "",
        description: data.description ? data.description : null,
        price: data.price ? data.price : 0,
        tags: data.tags ? data.tags : [],
        accountId: data.accountId,
        chapterBankId: data.chapterId,
      }
      const res = await API.put(ENDPOINT, "/editChaptersBankTree", {
        headers: headers,
        body: body,
      })
      let aux = { ...res, chapterInfo: res }
      dispatch({
        type: "UPDATE_CAPITULO",
        payload: {
          newData: aux,
          capituloId: data.chapterId,
        },
      })
    } catch (e) {
      console.log(e)
    }
  }
  const deleteCapitulo = async (capituloId, accountId) => {
    try {
      const payload = {
        headers: await commonHeaders(),
        body: {
          chapterBankId:
            capituloId == "SOYUNCAPITULOCONPARTIDASSINCLASIFICAR"
              ? "root"
              : capituloId,
          accountId: accountId,
        },
      }

      const res = await API.post(ENDPOINT, `/deleteChaptersBankTree`, payload)
      dispatch({
        type: "DEL_CAPITULO",
        payload: capituloId,
      })
    } catch (error) {
      console.log(error)
    }
  }

  function getSelectedCategories(selectedBudget, capitulos) {
    for (let i = 0; i < capitulos.length; i++) {
      if (capitulos[i].checked) {
        selectedBudget.push({ ...capitulos[i].capituloInfo })
      }
      if (capitulos[i].subcapitulos.length > 0) {
        getSelectedCategories(selectedBudget, capitulos[i].subcapitulos)
      }
    }
    dispatch({
      type: "SELECCIONAR_AGRUPAR",
      payload: {
        selectedBudget: selectedBudget,
      },
    })
  }

  const checkCapitulos = (capituloId, value) => {
    dispatch({
      type: "CHECK_CAPITULOS",
      payload: {
        capituloId,
        value,
      },
    })
  }
  const uncheckAll = () => {
    dispatch({
      type: "UNCHECK_ALL",
    })
  }
  const updateChaptersList = chapter => {
    let find = false
    let i = 0
    while (!find && i < state.categoriesChecked.length) {
      if (state.categoriesChecked[i].chapterBankId == chapter.chapterBankId) {
        state.categoriesChecked.splice(i, 1)
        find = true
      } else {
        i++
      }
    }
    if (find == false) {
      state.categoriesChecked.push(chapter)
    }
    dispatch({
      type: "UPDATE_CHAPTERS_LIST",
      payload: {
        list: state.categoriesChecked,
      },
    })
  }
  const checkMultipleChapters = async chapterId => {
    let parent = await getChapterParentId(
      chapterId,
      state.myPricesBank.subcapitulos
    )
    let chapter = {}
    if (parent != "root") {
      chapter = await getChapterById(parent, state.myPricesBank.subcapitulos)
    } else {
      chapter = state.myPricesBank
    }

    if (chapter.subcapitulos.length > 0) {
      let indiceChapter = await getChapterIndex(chapter, chapterId)
      let i = 0
      let trobat1 = false
      let trobat2 = false
      while ((!trobat1 || !trobat2) && i < chapter.subcapitulos.length) {
        if (chapter.subcapitulos[i].chapterId != chapterId) {
          if (chapter.subcapitulos[i].checked == true) {
            if (i < indiceChapter) {
              for (let j = i + 1; j < indiceChapter + 1; j++) {
                //indice +1 porque quiero que marque la que hemos seleccionado tb
                dispatch({
                  type: "CHECK_CAPITULOS",
                  payload: {
                    capituloId: chapter.subcapitulos[j].chapterId,
                    value: true,
                  },
                })
                updateChaptersList(chapter.subcapitulos[j].capituloInfo)
              }
              trobat1 = true
              i = indiceChapter
            } else {
              let valorIni = indiceChapter
              for (let j = indiceChapter; j < i; j++) {
                if (j == valorIni) {
                  if (chapter.subcapitulos[valorIni].checked == false) {
                    dispatch({
                      type: "CHECK_CAPITULOS",
                      payload: {
                        capituloId: chapter.subcapitulos[j].chapterId,
                        value: true,
                      },
                    })
                    updateChaptersList(chapter.subcapitulos[j].capituloInfo)
                  }
                } else {
                  dispatch({
                    type: "CHECK_CAPITULOS",
                    payload: {
                      capituloId: chapter.subcapitulos[j].chapterId,
                      value: true,
                    },
                  })
                  updateChaptersList(chapter.subcapitulos[j].capituloInfo)
                }
              }
              trobat2 = true
              i++
            }
          } else {
            i++
          }
        } else {
          i++
        }
      }
    }
  }
  //#region Move Part
  const movePartsFromBankPrices = async (chapter, parts) => {
    const partsToMove = parts
    for (let part of partsToMove) {
      dispatch({
        type: "DEL_PARTIDA",
        payload: {
          capituloId:
            part.chapterId == "root"
              ? "SOYUNCAPITULOCONPARTIDASSINCLASIFICAR"
              : part.chapterId,
          partidaId: part.partidaId,
        },
      })

      dispatch({
        type: "ADD_PARTIDA",
        payload: {
          chapterId: chapter.chapterId,
          partidaNueva: part,
          index: chapter.partidas.length,
        },
      })

      let headers = await commonHeaders()

      const res = await API.put(ENDPOINT, "/part-bank/move", {
        headers: headers,
        body: { partId: part.partidaId, chapterId: chapter.chapterId },
      })
      //partaid, chapteriddelqueviene, chapteridnuevo
    }
  }
  const movePartsWithArrows = async (part, movement) => {
    const partToMove = part
    var partToChange = {}
    if (movement == "up") {
      partToChange = await getPreviousPartByParentAndPosition(
        partToMove.chapterId,
        state.myPricesBank.subcapitulos,
        partToMove.position,
        false,
        0,
        {}
      )
      dispatch({
        type: "DEL_PARTIDA",
        payload: {
          capituloId:
            partToMove.chapterId == "root"
              ? "SOYUNCAPITULOCONPARTIDASSINCLASIFICAR"
              : partToMove.chapterId,
          partidaId: partToMove.partidaId,
        },
      })
      dispatch({
        type: "DEL_PARTIDA",
        payload: {
          capituloId:
            partToChange.chapterId == "root"
              ? "SOYUNCAPITULOCONPARTIDASSINCLASIFICAR"
              : partToChange.chapterId,
          partidaId: partToChange.partidaId,
        },
      })

      let partToMovePosition = partToMove.position
      let partToChangePosition = partToChange.position
      dispatch({
        type: ADD_PARTIDA_TO_MOVE,
        payload: {
          chapterId: partToMove.chapterId,
          partidaNueva: partToMove,
          index: partToChangePosition,
        },
      })
      dispatch({
        type: ADD_PARTIDA_TO_MOVE,
        payload: {
          chapterId: partToChange.chapterId,
          partidaNueva: partToChange,
          index: partToMovePosition,
        },
      })
    } else {
      partToChange = await getNextPartByParentAndPosition(
        partToMove.chapterId,
        state.myPricesBank.subcapitulos,
        partToMove.position,
        false,
        0,
        {}
      )
      dispatch({
        type: "DEL_PARTIDA",
        payload: {
          capituloId:
            partToChange.chapterId == "root"
              ? "SOYUNCAPITULOCONPARTIDASSINCLASIFICAR"
              : partToChange.chapterId,
          partidaId: partToChange.partidaId,
        },
      })
      dispatch({
        type: "DEL_PARTIDA",
        payload: {
          capituloId:
            partToMove.chapterId == "root"
              ? "SOYUNCAPITULOCONPARTIDASSINCLASIFICAR"
              : partToMove.chapterId,
          partidaId: partToMove.partidaId,
        },
      })

      let partToMovePosition = partToMove.position
      let partToChangePosition = partToChange.position
      dispatch({
        type: ADD_PARTIDA_TO_MOVE,
        payload: {
          chapterId: partToChange.chapterId,
          partidaNueva: partToChange,
          index: partToMovePosition,
        },
      })
      dispatch({
        type: ADD_PARTIDA_TO_MOVE,
        payload: {
          chapterId: partToMove.chapterId,
          partidaNueva: partToMove,
          index: partToChangePosition,
        },
      })
    }
    try {
      let parentChapter = await getChapterById(
        partToMove.chapterId,
        state.myPricesBank.subcapitulos
      )
      const position = movement === "down" ? 1 : -1
      let payload = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
          "Access-Control-Allow-Origin": "*",
        },
        body: {
          partBankId: partToMove.partidaId,
          position: position,
        },
      }
      await API.patch(ENDPOINT, "/part/position", payload)
    } catch (error) {
      console.log(error)
    }
  }
  //#endregion
  //#region Move  Chapter
  const moveChapterUp = async chapterToMove => {
    const chapterToChange = await getChapterByParentAndPosition(
      chapterToMove.parentId,
      state.myPricesBank.subcapitulos,
      chapterToMove.capituloInfo.position - 1,
      false,
      0,
      {}
    )
    dispatch({
      type: DEL_CAPITULO,
      payload: chapterToChange.chapterId,
    })
    dispatch({
      type: DEL_CAPITULO,
      payload: chapterToMove.chapterId,
    })
    dispatch({
      type: ADD_CAPITULO_MOVE,
      payload: {
        parentId: chapterToMove.parentId,
        res: {
          ...chapterToMove,
          capituloInfo: chapterToMove.capituloInfo,
          position: chapterToChange.capituloInfo.position,
        },
        indexCapitulo: chapterToChange.capituloInfo.position,
      },
    })
    dispatch({
      type: ADD_CAPITULO_MOVE,
      payload: {
        parentId: chapterToChange.parentId,
        res: {
          ...chapterToChange,
          capituloInfo: chapterToChange.capituloInfo,
          position: chapterToMove.capituloInfo.position,
        },
        indexCapitulo: chapterToMove.capituloInfo.position,
      },
    })
  }

  const moveChapterDown = async chapterToMove => {
    const chapterToChange = await getChapterByParentAndPosition(
      chapterToMove.parentId,
      state.myPricesBank.subcapitulos,
      chapterToMove.capituloInfo.position + 1,
      false,
      0,
      {}
    )
    dispatch({
      type: DEL_CAPITULO,
      payload: chapterToChange.chapterId,
    })
    dispatch({
      type: DEL_CAPITULO,
      payload: chapterToMove.chapterId,
    })
    dispatch({
      type: ADD_CAPITULO_MOVE,
      payload: {
        parentId: chapterToChange.parentId,
        res: {
          ...chapterToChange,
          capituloInfo: chapterToChange.capituloInfo,
          position: chapterToMove.capituloInfo.position,
        },
        indexCapitulo: chapterToMove.capituloInfo.position,
      },
    })
    dispatch({
      type: ADD_CAPITULO_MOVE,
      payload: {
        parentId: chapterToMove.parentId,
        res: {
          ...chapterToMove,
          capituloInfo: chapterToMove.capituloInfo,
          position: chapterToChange.capituloInfo.position,
        },
        indexCapitulo: chapterToChange.capituloInfo.position,
      },
    })
  }

  const moveChaptersWithArrows = async (chapter, movement) => {
    if (movement == "up") {
      moveChapterUp(chapter)
    } else {
      moveChapterDown(chapter)
    }
    try {
      const position = movement === "down" ? 1 : -1
      let payload = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
          "Access-Control-Allow-Origin": "*",
        },
        body: {
          chapterBankId: chapter.chapterId,
          position: position,
        },
      }
      await API.patch(ENDPOINT, "/chapter/position", payload)
    } catch (error) {
      console.log(error)
    }
  }
  //#endregion
  const createPartBankTree = async data => {
    try {
      let auxDecomposition = []
      if (data.decomposition) {
        for (let i = 0; i < data.decomposition.length; i++) {
          let aux = {
            type:
              data.decomposition[i].type == "coste"
                ? 0
                : data.decomposition[i].type == "manodeobra"
                ? 1
                : data.decomposition[i].type == "maquinaria"
                ? 2
                : data.decomposition[i].type == "residuoAdicional"
                ? 4
                : data.decomposition[i].type == "residuoClasificacion"
                ? 5
                : data.decomposition[i].type == "material"
                ? 3
                : 1,

            unity: data.decomposition[i].unity,
            quantity: parseFloat(data.decomposition[i].quantity),
            price: parseFloat(data.decomposition[i].price),
            import: parseFloat(data.decomposition[i].import),
            description: data.decomposition[i].description,
          }
          auxDecomposition.push(aux)
        }
      }
      let payload = {
        headers: await commonHeaders(),
        body: { ...data, decomposition: auxDecomposition },
      }
      const partida = await API.post(ENDPOINT, "/createPartBankTree", payload)
      let aux = {
        ...partida,
        elementInfo: null,
        chapterId: partida.chapterBankId,
      }
      let partidaNueva = new Partida(aux)
      dispatch({
        type: "ADD_PARTIDA",
        payload: {
          chapterId: data.chapterBankId,
          partidaNueva: partidaNueva,
          index: data.position,
        },
      })
    } catch (error) {
      console.log(error)
    }
  }

  const deleteBankPart = async (capituloId, partidaId, accountId) => {
    try {
      let payload = {
        headers: await commonHeaders(),
        body: {
          partBankId: partidaId,
          chapterId:
            capituloId == "SOYUNCAPITULOCONPARTIDASSINCLASIFICAR"
              ? "root"
              : capituloId,
          accountId: accountId,
        },
      }

      const res = await API.del(ENDPOINT, "/deletePartBankTree", payload)
      dispatch({
        type: "DEL_PARTIDA",
        payload: {
          capituloId:
            capituloId == "root"
              ? "SOYUNCAPITULOCONPARTIDASSINCLASIFICAR"
              : capituloId,
          partidaId,
        },
      })
    } catch (error) {
      console.log(error)
    }
  }
  const cleanImportProgress = () => {
    dispatch({
      type: CLEAN_IMPORT_PROGRESS,
    })
  }
  const updatePartsList = partida => {
    let find = false
    let i = 0
    while (!find && i < state.partsChecked.length) {
      if (state.partsChecked[i].partidaId == partida.partidaId) {
        state.partsChecked.splice(i, 1)
        find = true
      } else {
        i++
      }
    }
    if (find == false) {
      state.partsChecked.push(partida)
    }

    dispatch({
      type: "UPDATE_PARTS_LIST",
      payload: {
        list: state.partsChecked,
      },
    })
  }

  const checkPartida = (capituloId, partidaId) => {
    dispatch({
      type: "CHECK_PARTIDA",
      payload: {
        capituloId:
          capituloId == "root"
            ? "SOYUNCAPITULOCONPARTIDASSINCLASIFICAR"
            : capituloId,
        partidaId,
      },
    })
  }

  const getPartsLenghtByChapterId = async chapterId => {
    let chapter = await getChapterById(
      chapterId,
      state.myPricesBank.subcapitulos
    )
    if (chapter.partidas && chapter.partidas.length > 0) {
      return chapter.partidas.length
    } else {
      return 0
    }
  }

  const checkMultipleParts = async (chapterId, part) => {
    let chapterId2 = chapterId
    if (chapterId == "root") {
      chapterId2 = "SOYUNCAPITULOCONPARTIDASSINCLASIFICAR"
    }
    let chapter = await getChapterById(
      chapterId2,
      state.myPricesBank.subcapitulos
    )
    if (chapter.partidas.length > 0) {
      //busqueda para el indice de la partida
      let indicePartida = await getPartIndex(chapter, part.partidaId)
      //busqueda para ver que partidas se han checkeado antes de (part)
      let i = 0
      let trobat1 = false
      let trobat2 = false
      while ((!trobat1 || !trobat2) && i < chapter.partidas.length) {
        if (chapter.partidas[i].partidaId != part.partidaId) {
          if (chapter.partidas[i].checked == true) {
            if (i < indicePartida) {
              for (let j = i + 1; j < indicePartida + 1; j++) {
                dispatch({
                  type: "CHECK_PARTIDA",
                  payload: {
                    capituloId:
                      chapterId == "root"
                        ? "SOYUNCAPITULOCONPARTIDASSINCLASIFICAR"
                        : chapterId,
                    partidaId: chapter.partidas[j].partidaId,
                  },
                })
                state.partsChecked.push(chapter.partidas[j])
                dispatch({
                  type: "UPDATE_PARTS_LIST",
                  payload: {
                    list: state.partsChecked,
                  },
                })
              }
              trobat1 = true
              i = indicePartida
            } else {
              let valorIni = indicePartida
              for (let j = indicePartida; j < i; j++) {
                if (j == valorIni) {
                  if (chapter.partidas[valorIni].checked == false) {
                    dispatch({
                      type: "CHECK_PARTIDA",
                      payload: {
                        capituloId:
                          chapterId == "root"
                            ? "SOYUNCAPITULOCONPARTIDASSINCLASIFICAR"
                            : chapterId,
                        partidaId: chapter.partidas[j].partidaId,
                      },
                    })
                    state.partsChecked.push(chapter.partidas[j])
                    dispatch({
                      type: "UPDATE_PARTS_LIST",
                      payload: {
                        list: state.partsChecked,
                      },
                    })
                  }
                } else {
                  dispatch({
                    type: "CHECK_PARTIDA",
                    payload: {
                      capituloId:
                        chapterId == "root"
                          ? "SOYUNCAPITULOCONPARTIDASSINCLASIFICAR"
                          : chapterId,
                      partidaId: chapter.partidas[j].partidaId,
                    },
                  })
                  state.partsChecked.push(chapter.partidas[j])
                  dispatch({
                    type: "UPDATE_PARTS_LIST",
                    payload: {
                      list: state.partsChecked,
                    },
                  })
                }
              }
              trobat2 = true
              i++
            }
          } else {
            i++
          }
        } else {
          //esquivo la partida ya marcada y paso a la siguiente
          i++
        }
      }
    }
  }
  function selectPartsGroup(selectedParts, capitulos) {
    for (let i = 0; i < capitulos.length; i++) {
      if (capitulos[i].checked) {
        dispatch({
          type: "ERROR_CAPITULO",
          payload: {
            error: true,
          },
        })
        return false
      } else {
        if (capitulos[i].partidas.length > 0) {
          let copia = []
          for (let j = 0; j < capitulos[i].partidas.length; j++) {
            if (capitulos[i].partidas[j].checked == true) {
              copia.push(capitulos[i].partidas[j])
            }
          }
          if (copia.length > 0) {
            if (!copia.isEmpty) {
              selectedParts.push(copia)
            }
          }
        }
        if (capitulos[i].subcapitulos.length > 0) {
          selectPartsGroup(selectedParts, capitulos[i].subcapitulos)
        }
      }
    }
    dispatch({
      type: "SET_GROUP",
      payload: {
        selectedParts: selectedParts,
        error: false,
      },
    })
  }

  const updatePartPrice = async data => {
    try {
      let headers = await commonHeaders()
      let auxDecomposition = data.decomposition
      if (data.decomposition.length > 0) {
        for (let i = 0; i < data.decomposition.length; i++) {
          auxDecomposition[i].price = parseFloat(
            auxDecomposition[i].price.toString().replace(",", ".")
          )
          auxDecomposition[i].quantity = parseFloat(
            auxDecomposition[i].quantity.toString().replace(",", ".")
          )

          auxDecomposition[i].type =
            auxDecomposition[i].type == "coste"
              ? 0
              : auxDecomposition[i].type == "manodeobra"
              ? 1
              : auxDecomposition[i].type == "maquinaria"
              ? 2
              : auxDecomposition[i].type == "residuoAdicional"
              ? 4
              : auxDecomposition[i].type == "residuoClasificacion"
              ? 5
              : auxDecomposition[i].type == "material"
              ? 3
              : 1
        }
      }
      const res = await API.put(ENDPOINT, "/editPartBankTree", {
        headers: headers,
        body: {
          ...data,
          partBankId: data.partidaId,
          decomposition: auxDecomposition ? auxDecomposition : [],
        },
      })
      dispatch({
        type: "UPDATE_PARTIDA",
        payload: {
          newData: data,
          capituloId: data.chapterId,
          final: false,
        },
      })
    } catch (e) {
      console.log(e)
    }
  }

  const cleanParts = async () => {
    dispatch({
      type: "UPDATE_PARTS_LIST",
      payload: {
        list: [],
      },
    })
  }

  const cleanChapters = async () => {
    state.categoriesChecked = []
    for (let i = 0; i < state.myPricesBank.subcapitulos.length; i++) {
      if (state.myPricesBank.subcapitulos[i].checked === true) {
        state.myPricesBank.subcapitulos[i].checked = false
      }
    }
    dispatch({
      type: "UPDATE_CHAPTERS_LIST",
      payload: {
        list: [],
      },
    })
  }
  function getSelectedChapters(selectedBudget, auxList, totalCaps, start) {
    for (let i = 0; i < totalCaps.length; i++) {
      if (totalCaps[i].checked) {
        if (totalCaps[i].subcapitulos.length > 0) {
          let auxChapter = { ...totalCaps[i] }
          auxChapter.subcapitulos = []
          auxList.push(auxChapter)
          getSelectedChapters(
            selectedBudget,
            auxList[auxList.length - 1].subcapitulos,
            totalCaps[i].subcapitulos,
            start
          )
        } else if (totalCaps[i].partidas.length > 0) {
          let auxChapter = { ...totalCaps[i] }
          auxChapter.partidas = []
          let copia = []
          for (let j = 0; j < totalCaps[i].partidas.length; j++) {
            if (totalCaps[i].partidas[j].checked == true) {
              copia.push(totalCaps[i].partidas[j])
            }
          }
          auxChapter.partidas = copia
          auxList.push(auxChapter)
        } else {
          auxList.push(totalCaps[i])
        }
      } else {
        if (totalCaps[i].subcapitulos.length > 0) {
          getSelectedChapters(
            selectedBudget,
            auxList,
            totalCaps[i].subcapitulos,
            start
          )
        }
      }
    }
    if (auxList.length == 0) {
      selectedBudget = state.myPricesBank.subcapitulos
    }
    dispatch({
      type: "SET_SELECTED",
      payload: {
        selectedBudget: auxList,
      },
    })
  }

  function getSelectedChaptersToFav(
    selectedBudget,
    auxList,
    totalCaps,
    start,
    accountId
  ) {
    dispatch({
      type: LOADING_SUBCAPS,
    })
    for (let i = 0; i < totalCaps.length; i++) {
      if (totalCaps[i].checked) {
        if (totalCaps[i].subcapitulos.length > 0) {
          let auxChapter = { ...totalCaps[i] }
          auxChapter.subcapitulos = []
          auxList.push(auxChapter)
          getSelectedChaptersToFav(
            selectedBudget,
            auxList[auxList.length - 1].subcapitulos,
            totalCaps[i].subcapitulos,
            start,
            accountId
          )
        } else if (totalCaps[i].partidas.length > 0) {
          let auxChapter = { ...totalCaps[i] }
          auxChapter.partidas = []
          let copia = []
          for (let j = 0; j < totalCaps[i].partidas.length; j++) {
            if (totalCaps[i].partidas[j].checked == true) {
              copia.push(totalCaps[i].partidas[j])
            }
          }
          auxChapter.partidas = copia
          auxList.push(auxChapter)
        } else {
          auxList.push(totalCaps[i])
        }
      } else {
        if (totalCaps[i].subcapitulos.length > 0) {
          getSelectedChaptersToFav(
            selectedBudget,
            auxList,
            totalCaps[i].subcapitulos,
            start,
            accountId
          )
        }
      }
    }
    if (auxList.length == 0) {
      selectedBudget = state.myPricesBank.subcapitulos
    }
    addChaptersToFavorites(auxList, "USER_DATABASE", accountId)
  }

  const importChaptersFromDatabase = async (
    chapters,
    projectId,
    accountId,
    chapterId
  ) => {
    try {
      let headers = await commonHeaders()
      let body = {
        chapters: chapters,
        projectId: projectId,
        accountId: accountId,
        chapterId: chapterId,
      }
      const res = await API.post(
        "service-budget",
        "/importChaptersFromDatabase",
        { headers: headers, body: body }
      )
    } catch (e) {
      console.log(e)
    }
  }

  const uploadFile = async ({ file, presignedPostData }) => {
    try {
      const formData = new FormData()
      Object.keys(presignedPostData.fields).forEach(key => {
        formData.append(key, presignedPostData.fields[key])
      })
      formData.append("file", file)
      const res = await axios.post(presignedPostData.url, formData)
      dispatch({
        type: UPLOAD_FILE_SUCCESS,
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  const importExcel = async data => {
    try {
      let payload = {
        headers: await commonHeaders(),
        body: {
          filename: data.file.name,
          extension: data.file.name.split(".").slice(-1)[0],
          size: data.file.size,
          contentType: data.file.type,
          customDB: false,
          importedProject: false,
          tags: [],
          description: "",
          orgId: "",
        },
      }
      const res1 = await API.post(
        "dev-PLANHOPPER-API",
        "/api/file/upload",
        payload
      )
      const res2 = await uploadFile({
        file: data.file,
        presignedPostData: res1.uploadInfo,
      })

      let headers = await commonHeaders()
      let body = {
        key: res1.uploadInfo.fields.key,
        accountId: data.accountId,
      }
      const res = await API.post(ENDPOINT, "/createPartBankFromExcel", {
        headers: headers,
        body: body,
      })
    } catch (e) {
      console.log(e)
    }
  }

  const importFile = async data => {
    try {
      dispatch({
        type: ON_UPLOAD_FILE,
      })
      let payload = {
        headers: await commonHeaders(),
        body: {
          filename: data.file.name,
          extension: data.file.name.split(".").slice(-1)[0],
          size: data.file.size,
          contentType: data.file.type,
          customDB: false,
          importedProject: false,
          tags: [],
          description: "",
          orgId: "",
          accountId: data.accountId,
          importedTemplate: false,
          importedDatabase: true,
        },
      }
      const res = await API.post("service-budget", "/import/database", payload)
      dispatch({
        type: "UPDATE_FILE_UPLOADED",
        payload: res.file.fileId,
      })
      uploadFile({ file: data.file, presignedPostData: res.uploadInfo })
    } catch (e) {
      dispatch({
        type: UPLOAD_FILE_ERROR,
      })
    }
  }

  const createPartsOnBudget = async (
    accountId,
    parts,
    projectId,
    chapterId,
    capituloEntero
  ) => {
    try {
      let auxChapterId
      if (chapterId == "root") {
        const path = "/chapter"
        let payload = {
          headers: await commonHeaders(),
          body: {
            name: "PARTIDAS SIN CLASIFICAR",
            description: "",
            parentId: chapterId,
            projectId: projectId,
            price: 0,
            tags: [],
            status: "OPEN",
            position: 0,
            accountId: accountId,
          },
        }
        const res = await API.post(SERVICE_BUDGET_TEMP, path, payload)
        auxChapterId = res.chapterId
      } else {
        auxChapterId = chapterId
      }
      for (let i = 0; i < parts.length; i++) {
        let auxDecomposition = []
        if (parts[i].decomposition) {
          for (let j = 0; j < parts[i].decomposition.length; j++) {
            let aux = {
              type:
                parts[i].decomposition[j].type == "coste"
                  ? 0
                  : parts[i].decomposition[j].type == "manodeobra"
                  ? 1
                  : parts[i].decomposition[j].type == "maquinaria"
                  ? 2
                  : parts[i].decomposition[j].type == "residuoAdicional"
                  ? 4
                  : parts[i].decomposition[j].type == "residuoClasificacion"
                  ? 5
                  : parts[i].decomposition[j].type == "material"
                  ? 3
                  : 1,
              unity: parts[i].decomposition[j].unity,
              quantity: parseFloat(parts[i].decomposition[j].quantity),
              price: parseFloat(parts[i].decomposition[j].price),
              import: parseFloat(parts[i].decomposition[j].import),
              description: parts[i].decomposition[j].description,
            }
            auxDecomposition.push(aux)
          }
        }
        let payload = {
          headers: await commonHeaders(),
          body: {
            chapterId: auxChapterId,
            code: parts[i].code,
            name: parts[i].name,
            description: parts[i].description,
            quantity: 0,
            userPrice: parseFloat(
              parts[i].userPrice ? parts[i].userPrice : parts[i].price
            ),
            price: parseFloat(
              parts[i].userPrice ? parts[i].userPrice : parts[i].price
            ),
            costeGeneral: 0,
            costeIndirecto: 0,
            decomposition: auxDecomposition,
            industrialProfit: 0,
            projectId: projectId,
            status: "OPEN",
            position:
              chapterId == "root" ? i : capituloEntero.partidas.length + i,
            accountId: accountId,
            refPrice: parseFloat(parts[i].price),
          },
        }
        const res = await API.post(SERVICE_BUDGET_TEMP, "/part", payload)
        amplitude.track({
          event_type: "Imported Part from Bank Price",
          event_properties: { bankPrice: "My Bank Price" },
        })
      }
    } catch (e) {
      console.log(e)
    }
  }

  const addChaptersToFavorites = async (chapters, table, accountId) => {
    //REFERENCE_DATABASE, USER_DATABASE, TEMPLATES
    try {
      let payload = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
          "Access-Control-Allow-Origin": "*",
        },
        body: {
          chapters: chapters,
          table: table,
          accountId: accountId,
        },
      }
      const res = await API.post(ENDPOINT, "/addToFavourites", payload)
      dispatch({
        type: "LOADING_SUBCAPS_SUCCESS",
      })
      //cleanChapters()
    } catch (e) {
      console.log(e)
    }
  }

  const orderParts = capitulo => {
    dispatch({
      type: "ORDER_PARTS",
      payload: {
        loadingOrder: true,
      },
    })
    console.log(capitulo)
    capitulo.partidas.sort(function (a, b) {
      if (a.name < b.name) {
        return -1
      }
      if (a.name > b.name) {
        return 1
      }
      return 0
    })
    dispatch({
      type: "ORDER_PARTS",
      payload: {
        loadingOrder: false,
        capitulo: capitulo,
      },
    })
  }

  const getMyBankPricesWithoutClassify = async accountId => {
    try {
      const headers = await commonHeaders()
      const res = await API.get(
        ENDPOINT,
        `/getNonSpecifiedParts?accountId=` + accountId + `&parentId=root`,
        { headers: headers }
      )
      let aux = {
        name: "PARTIDAS SIN CLASIFICAR",
        description: "",
        parentId: "root",
        price: 0,
        tags: [],
        accountId: accountId,
        code: null,
        root: true,
        position: 0,
        parts: res.myParts,
        chapterBankId: "SOYUNCAPITULOCONPARTIDASSINCLASIFICAR",
        chapterInfo: {
          name: "PARTIDAS SIN CLASIFICAR",
          description: "",
          parentId: "root",
          price: 0,
          tags: [],
          accountId: accountId,
          code: null,
          root: true,
          position: 0,
          chapterBankId: "SOYUNCAPITULOCONPARTIDASSINCLASIFICAR",
        },
      }
      if (res.myParts.length > 0) {
        dispatch({
          type: "ADD_CAPITULO",
          payload: {
            parentId: aux.parentId,
            res: aux,
            indexCapitulo: aux.position,
          },
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <BankPricesContext.Provider
      value={{
        getMyBankPrices,
        fileUploaded: state.fileUploaded,
        myPricesBank: state.myPricesBank,
        loading: state.loading,
        loadingCaps: state.loadingCaps,
        importProgress: state.importProgress,
        getSubCaps,
        createInitialStructure,
        addBankChapter,
        updateChapterBank,
        getSelectedCategories,
        selectedCategories: state.selectedCategories,
        deleteCapitulo,
        checkCapitulos,
        updateChaptersList,
        checkMultipleChapters,
        categoriesChecked: state.categoriesChecked,
        createPartBankTree,
        deleteBankPart,
        checkPartida,
        uncheckAll,
        movePartsFromBankPrices,
        movePartsWithArrows,
        moveChaptersWithArrows,
        getPartsLenghtByChapterId,
        checkMultipleParts,
        partsChecked: state.partsChecked,
        updatePartsList: updatePartsList,
        selectPartsGroup: selectPartsGroup,
        selectedParts: state.selectedParts,
        error: state.error,
        errorAgrupar: state.errorAgrupar,
        selectPartsGroup: selectPartsGroup,
        selectedPartGroup: state.selectedPartGroup,
        updatePartPrice,
        getSelectedChapters,
        selectedCategories2: state.selectedCategories2,
        importChaptersFromDatabase,
        importExcel,
        importFile,
        createPartsOnBudget,
        cleanParts,
        cleanChapters,
        getMyBankPricesWithoutClassify,
        myNonSpecifiedPricesBank: state.myNonSpecifiedPricesBank,
        addChaptersToFavorites,
        getSelectedChaptersToFav,
        getSearchChapters,
        cleanImportProgress,
        orderParts,
        loadingOrder: state.loadingOrder,
      }}
    >
      {props.children}
    </BankPricesContext.Provider>
  )
}
BankPricesState.propTypes = {
  children: PropTypes.element.isRequired,
}

export default BankPricesState
