import React from "react"
import PropTypes from "prop-types"
import i18n from "i18n"
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  Modal,
  Typography,
} from "@mui/material"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
}

const CanNotDeleteModal = ({
  open,
  onClose,
  elementToDelete,
  partsRelated,
}) => {
  const elementName = elementToDelete?.description
  return (
    <Modal
      open={open}
      onClose={() => onClose(false)}
      aria-labelledby="confirm-delete-title"
      aria-describedby="confirm-delete-description"
    >
      <Box sx={style}>
        <Typography id="confirm-delete-title" variant="h6" component="h2">
          {i18n.t("dynamicElement.modal.notAllowed")}
        </Typography>
        <Box sx={{ mt: 2 }}>
          <Typography id="confirm-delete-description">
            {i18n.t("dynamicElement.modal.youCantDelete", { elementName })}
          </Typography>
          <List>
            {partsRelated?.map((part, index) => (
              <List key={`part-related-${index}`}>
              <ListItem>
                <ListItemText
                  primary={`${i18n.t("dynamicElement.columns.code")}: ${part.code}`}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={`${i18n.t("dynamicElement.columns.description")}: ${part.name}`}
                />                    
              </ListItem>
              </List>
            ))}
          </List>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => onClose(false)}
            sx={{ mr: 1 }}
          >
            {i18n.t("buttons.accept")}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

CanNotDeleteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  elementToDelete: PropTypes.object,
  partsRelated: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.oneOf([null, undefined]),
  ]),
}

export default CanNotDeleteModal
