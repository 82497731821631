import React, { useState } from "react"
import PropTypes from "prop-types"
import i18n from "i18n"
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  Modal,
  Typography,
  CircularProgress,
} from "@mui/material"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
}

const ConfirmDeleteModal = ({ open, onClose, onConfirm, elementToDelete }) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleConfirm = async () => {
    setIsLoading(true)
    try {
      await onConfirm()
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      open={open}
      onClose={() => onClose(false)}
      aria-labelledby="confirm-delete-title"
      aria-describedby="confirm-delete-description"
    >
      <Box sx={style}>
        <Typography id="confirm-delete-title" variant="h6" component="h2">
          {i18n.t("dynamicElement.modal.confirm")}
        </Typography>
        <Typography id="confirm-delete-description" sx={{ mt: 2 }}>
          {i18n.t("dynamicElement.modal.areYouSure")}
        </Typography>
        <Box sx={{ mt: 2 }}>
          <List>
            <ListItem>
              <ListItemText
                primary={`${i18n.t("dynamicElement.columns.code")}: ${
                  elementToDelete?.code || ""
                }`}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={`${i18n.t("dynamicElement.columns.description")}: ${
                  elementToDelete?.description
                }`}
              />
            </ListItem>
          </List>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
          <Button
            variant="outlined"
            onClick={() => onClose(false)}
            sx={{ mr: 1 }}
          >
            {i18n.t("buttons.cancel")}
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={handleConfirm}
            disabled={isLoading}
          >
            {isLoading ? (
              <CircularProgress size={20} />
            ) : (
              i18n.t("buttons.accept")
            )}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

ConfirmDeleteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  elementToDelete: PropTypes.object,
  partsRelated: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.oneOf([null, undefined]),
  ]),
}

export default ConfirmDeleteModal
